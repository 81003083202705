import React, { useEffect, useState } from "react";
import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Checkbox } from "./ui/checkbox";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Slider } from "./ui/slider";
import { Separator } from "./ui/separator";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "./ui/collapsible";
import { ScrollArea } from "./ui/scroll-area";
import {
  Brain,
  Dna,
  Microscope,
  BookOpen,
  Beaker,
  AtomIcon,
  Zap,
  ChevronDown,
  User,
} from "lucide-react";
import { supabase } from "../supabaseClient";

const subjects = [
  {
    name: "Behavioral Sciences",
    icon: <Brain className="w-4 h-4" />,
    totalQuestions: 301,
    topics: [
      { name: "Sensation, Perception, and Consciousness", questions: 50 },
      { name: "Learning, Memory, and Cognition", questions: 79 },
      {
        name: "Motivation, Emotion, Attitudes, Personality, and Stress",
        questions: 33,
      },
      { name: "Identity and Social Interaction", questions: 78 },
      { name: "Demographics and Social Structure", questions: 61 },
    ],
  },
  {
    name: "Biochemistry",
    icon: <Dna className="w-4 h-4" />,
    totalQuestions: 532,
    topics: [
      { name: "Amino Acids and Proteins", questions: 187 },
      { name: "Enzymes", questions: 114 },
      { name: "Carbohydrates, Nucleotides, and Lipids", questions: 90 },
      { name: "Metabolic Reactions", questions: 141 },
    ],
  },
  {
    name: "Biology",
    icon: <Microscope className="w-4 h-4" />,
    totalQuestions: 520,
    topics: [
      { name: "Molecular Biology", questions: 71 },
      { name: "Cellular Biology", questions: 63 },
      { name: "Genetics and Evolution", questions: 62 },
      { name: "Reproduction", questions: 50 },
      { name: "Endocrine and Nervous Systems", questions: 83 },
      { name: "Circulation and Respiration", questions: 49 },
      { name: "Digestion and Excretion", questions: 57 },
      { name: "Musculoskeletal System", questions: 44 },
      { name: "Skin and Immune Systems", questions: 41 },
    ],
  },
  {
    name: "Critical Analysis & Reasoning Skills",
    icon: <BookOpen className="w-4 h-4" />,
    totalQuestions: 459,
    topics: [
      { name: "Humanities", questions: 199 },
      { name: "Social Sciences", questions: 260 },
    ],
  },
  {
    name: "General Chemistry",
    icon: <AtomIcon className="w-4 h-4" />,
    totalQuestions: 478,
    topics: [
      { name: "Atomic Theory and Chemical Composition", questions: 118 },
      { name: "Interactions of Chemical Substances", questions: 99 },
      { name: "Thermodynamics, Kinetics & Gas Laws", questions: 126 },
      { name: "Solutions and Electrochemistry", questions: 135 },
    ],
  },
  {
    name: "Organic Chemistry",
    icon: <Beaker className="w-4 h-4" />,
    totalQuestions: 315,
    topics: [
      { name: "Introduction to Organic Chemistry", questions: 79 },
      { name: "Functional Groups and Their Reactions", questions: 158 },
      {
        name: "Separation Techniques, Spectroscopy, and Analytical Methods",
        questions: 78,
      },
    ],
  },
  {
    name: "Physics",
    icon: <Zap className="w-4 h-4" />,
    totalQuestions: 394,
    topics: [
      { name: "Mechanics and Energy", questions: 99 },
      { name: "Fluids", questions: 91 },
      { name: "Electrostatics and Circuits", questions: 82 },
      { name: "Light and Sound", questions: 106 },
      { name: "Thermodynamics", questions: 16 },
    ],
  },
  // Add other subjects here...
];

export default function PremiumCreateTest(props) {
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [questionCount, setQuestionCount] = useState(59);
  // create a data structure to store the each topic and its questions in a state like a map where the key is topics and the value is the questions
  const [subjectMap, setSubjectMap] = useState({});
  const [loading, setLoading] = useState(true);

  // fetch all available  for each subjects and sub topics and store them in a state
  useEffect(() => {
    const fetchTopicQuestions = async () => {
      setLoading(true);
      const { data: passages, error: passagesError } = await supabase
        .from("passages")
        .select("id, subject, topic, questions(id)")
        .order("subject, topic");
      if (passagesError) {
        console.error(
          "Error fetching passages for questions count:",
          passagesError,
        );
        return;
      }
      const subjectsMap = passages.reduce((acc, passage) => {
        const { subject, topic, questions } = passage;
        const questionCount = questions.length;

        if (!acc[subject]) {
          acc[subject] = { name: subject, topics: {}, totalQuestions: 0 };
        }

        if (!acc[subject].topics[topic]) {
          acc[subject].topics[topic] = 0;
        }
        acc[subject].topics[topic] += questionCount;
        acc[subject].totalQuestions += questionCount;

        // const topicIndex = acc[subject].topics.findIndex(t => t.name === topic);
        // if (topicIndex === -1) {
        //   acc[subject].topics.push({ name: topic, questions: questionCount });
        // } else {
        //   acc[subject].topics[topicIndex].questions += questionCount;
        // }

        // acc[subject].totalQuestions += questionCount;

        // if (!acc[topic]) {
        //   acc[topic] = 0;
        // }
        // acc[topic] += questionCount;

        return acc;
      }, {});

      setSubjectMap(subjectsMap);
      setLoading(false);
    };

    fetchTopicQuestions();
  }, []);

  useEffect(() => {
    const totalSelectedQuestions = selectedTopics.reduce((total, topic) => {
      for (const subject in subjectMap) {
        if (subjectMap[subject].topics[topic]) {
          total += subjectMap[subject].topics[topic];
        }
      }
      return total;
    }, 0);

    setQuestionCount(totalSelectedQuestions > 59 ? 59 : totalSelectedQuestions);
  }, [selectedTopics, subjectMap]);

  const handleTopicToggle = (topicName) => {
    setSelectedTopics((prev) =>
      prev.includes(topicName)
        ? prev.filter((t) => t !== topicName)
        : [...prev, topicName],
    );
  };

  const handleSubjectToggle = (subjectName) => {
    const subject = subjects.find((s) => s.name === subjectName);
    const newTopics = subject.topics.map((t) => t.name);
    setSelectedTopics((prev) =>
      prev.some((t) => newTopics.includes(t))
        ? prev.filter((t) => !newTopics.includes(t))
        : [...prev, ...newTopics],
    );
  };

  const handleCreateTest = async () => {
    const { data: previousTests, error: previousTestsError } = await supabase
      .from("tests_new")
      .select("passage_ids")
      .eq("user_id", props.userId);
    if (previousTestsError) {
      console.error("Error fetching previous tests:", previousTestsError);
      return;
    }
    // Get the list of completed passage IDs
    const completedPassageIds = previousTests.flatMap(
      (test) => test.passage_ids || [],
    );
    // Now, fetch available passages matching the requested passage types
    let passagesToInclude = [];

    // query the database for the passages and questions, find the passages in the database that topics fit the selected topics and the question count is equal or less than the selected question count
    const { data: passages, error: passagesError } = await supabase
      .from("passages")
      .select("id, questions!inner(passage_id), question_count")
      .in("topic", selectedTopics)
      .not("id", "in", `(${completedPassageIds.join(",")})`)
      .lte("question_count", questionCount)
      .order("id");
    if (passagesError) {
      console.error("Error fetching passages:", passagesError);
      return;
    }
    if (passages.length === 0) {
      // No available passages
      console.error("No available passages to include in the test");
      return;
    }
    let questionCountVar = questionCount;
    for (const passage of passages) {
      if (passage.question_count < questionCountVar) {
        questionCountVar -= passage.question_count;
        passagesToInclude.push(passage.id);
      }
      if (questionCountVar === 0) {
        break;
      }
    }
    if (passagesToInclude.length === 0) {
      // No available passages
      console.error("No available passages to include in the test");
      return;
    }

    props.onCreateTest({ selectedTopics, questionCount, passagesToInclude });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Create Your Custom MCAT Test</CardTitle>
        <CardDescription>
          Select subjects and topics, then set the number of questions for your
          test.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div>
          <h3 className="text-lg font-semibold mb-2">Subjects and Topics</h3>
          <ScrollArea className="h-[400px] rounded-md border p-4">
            {subjects.map((subject) => (
              <Collapsible key={subject.name} className="mb-4">
                <CollapsibleTrigger className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    {subject.icon}
                    <span className="ml-2 font-medium">
                      <Checkbox
                        id={subject.name}
                        checked={subject.topics.every((t) =>
                          selectedTopics.includes(t.name),
                        )}
                        onCheckedChange={() =>
                          handleSubjectToggle(subject.name)
                        }
                      />
                    </span>
                    <span className="ml-2 font-medium">{subject.name}</span>
                    <span className="ml-2 text-sm text-gray-500">
                      ({subjectMap[subject.name].totalQuestions || 0})
                    </span>
                  </div>
                  <ChevronDown className="h-4 w-4" />
                </CollapsibleTrigger>
                <CollapsibleContent className="mt-2">
                  {subject.topics.map((topic) => (
                    <div
                      key={topic.name}
                      className="flex items-center space-x-2 ml-6 mt-2"
                    >
                      <Checkbox
                        id={topic.name}
                        checked={selectedTopics.includes(topic.name)}
                        onCheckedChange={() => handleTopicToggle(topic.name)}
                      />
                      <Label htmlFor={topic.name} className="text-sm">
                        {topic.name}
                        <span className="ml-2 text-gray-500">
                          ({subjectMap[subject.name].topics[topic.name] || 0})
                        </span>
                      </Label>
                    </div>
                  ))}
                </CollapsibleContent>
              </Collapsible>
            ))}
          </ScrollArea>
        </div>
        <Separator />
        <div>
          <h3 className="text-lg font-semibold mb-2">
            Max Number of Questions
          </h3>
          <div className="flex items-center space-x-4">
            <Input
              type="number"
              value={questionCount}
              onChange={(e) => setQuestionCount(Number(e.target.value))}
              max={59}
              min={0}
              className="w-20 bg-white border border-gray-300 rounded-md px-1 py-1"
            />
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <Button
          className="w-full bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white"
          onClick={handleCreateTest}
        >
          Create Test
        </Button>
      </CardFooter>
    </Card>
  );
}
