import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { CheckCircle, Star, Brain, AlertCircle } from "lucide-react";
import logoImage from "../assets/images/5.svg";
import { createClient } from "@supabase/supabase-js";

// Initialize Supabase client (add these near the top of the file)
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

export default function Beta() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    currentScore: "",
    targetScore: "",
    studyHours: "",
    testDate: "",
    prepResources: "",
    why: "",
  });
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus(null);

    // Log the form data being sent
    console.log("Submitting beta application:", {
      name: formData.name,
      email: formData.email,
      current_score: parseInt(formData.currentScore) || null,
      target_score: parseInt(formData.targetScore),
      study_hours: parseInt(formData.studyHours),
      test_date: formData.testDate,
      prep_resources: formData.prepResources,
      why: formData.why,
    });

    try {
      const { data, error } = await supabase
        .from("beta_applications")
        .insert([
          {
            name: formData.name,
            email: formData.email,
            current_score: parseInt(formData.currentScore) || null,
            target_score: parseInt(formData.targetScore),
            study_hours: parseInt(formData.studyHours),
            test_date: formData.testDate,
            prep_resources: formData.prepResources,
            why: formData.why,
          },
        ])
        .select();

      if (error) {
        console.error("Supabase error:", error);
        throw error;
      }

      console.log("Successfully submitted beta application:", data);
      setSubmitStatus("success");
      setFormData({
        name: "",
        email: "",
        currentScore: "",
        targetScore: "",
        studyHours: "",
        testDate: "",
        prepResources: "",
        why: "",
      });
    } catch (error) {
      console.error("Detailed error:", error);
      setSubmitStatus("error");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-green-50">
      {/* Refined Header - Reduced height */}
      <header className="relative bg-gradient-to-r from-blue-600 to-green-600 text-white py-20 px-4">
        <div className="absolute top-4 left-4 lg:left-6 flex items-center">
          <Link
            className="flex items-center justify-center transition-transform hover:scale-105"
            to="/"
          >
            <img src={logoImage} alt="UPangea Logo" className="h-12 w-12" />
            <span className="ml-1 text-3xl font-bold text-white">UPangea</span>
          </Link>
        </div>
        <div className="absolute top-4 right-4 lg:right-6">
          <Button
            asChild
            variant="outline"
            className="text-white border-white hover:bg-white/20 transition-colors"
          >
            <Link to="/">Back to Home</Link>
          </Button>
        </div>
        <div className="container mx-auto max-w-3xl text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-4 animate-fade-in">
            Join Our Beta Program
          </h1>
          <p className="text-lg md:text-xl opacity-90 max-w-2xl mx-auto">
            Be among the first to experience the future of MCAT preparation. Get
            exclusive benefits and help shape the platform.
          </p>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 -mt-8 pb-16 max-w-6xl relative z-10">
        {/* Beta Application Form */}
        <div className="max-w-2xl mx-auto mb-20">
          <div
            className="bg-white p-8 rounded-xl shadow-[0_20px_50px_rgba(8,_112,_184,_0.2)] 
            transition-all duration-300 hover:shadow-[0_30px_60px_rgba(8,_112,_184,_0.3)] 
            border border-gray-100"
          >
            <h2 className="text-2xl font-bold mb-6 text-gray-900 flex items-center">
              <Star className="w-6 h-6 text-blue-600 mr-2" />
              Beta Application
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <Label htmlFor="name">Full Name</Label>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your full name"
                  required
                  className="mt-1"
                />
              </div>

              <div>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="your@email.com"
                  required
                  className="mt-1"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Label htmlFor="currentScore">Current MCAT Score</Label>
                  <Input
                    id="currentScore"
                    name="currentScore"
                    type="number"
                    value={formData.currentScore}
                    onChange={handleChange}
                    placeholder="Current score"
                    className="mt-1"
                  />
                </div>

                <div>
                  <Label htmlFor="targetScore">Target Score</Label>
                  <Input
                    id="targetScore"
                    name="targetScore"
                    type="number"
                    value={formData.targetScore}
                    onChange={handleChange}
                    placeholder="Target score"
                    required
                    className="mt-1"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Label htmlFor="studyHours">Study Hours/Week</Label>
                  <Input
                    id="studyHours"
                    name="studyHours"
                    type="number"
                    value={formData.studyHours}
                    onChange={handleChange}
                    placeholder="Hours per week"
                    required
                    className="mt-1"
                  />
                </div>

                <div>
                  <Label htmlFor="testDate">Planned Test Date</Label>
                  <Input
                    id="testDate"
                    name="testDate"
                    type="date"
                    value={formData.testDate}
                    onChange={handleChange}
                    required
                    className="mt-1"
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="prepResources">
                  Current MCAT Prep Resources
                </Label>
                <textarea
                  id="prepResources"
                  name="prepResources"
                  value={formData.prepResources}
                  onChange={handleChange}
                  placeholder="What MCAT prep resources are you currently using or planning to use? (e.g., Blueprint, UWorld, Anki, Khan Academy, etc.)"
                  className="w-full min-h-[100px] px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              <div>
                <Label htmlFor="why">
                  Why do you want to join our beta program?
                </Label>
                <textarea
                  id="why"
                  name="why"
                  value={formData.why}
                  onChange={handleChange}
                  placeholder="Tell us why you'd be a great beta tester..."
                  required
                  className="w-full min-h-[100px] px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              <Button
                type="submit"
                className="w-full bg-gradient-to-r from-blue-600 to-green-600 text-white 
                  hover:from-blue-700 hover:to-green-700 transition-all duration-300 
                  transform hover:scale-[1.02] active:scale-[0.98]"
              >
                Apply for Beta Access
              </Button>

              {submitStatus === "success" && (
                <div className="bg-green-50 text-green-700 p-4 rounded-lg flex items-center justify-center space-x-2">
                  <CheckCircle className="w-5 h-5" />
                  <p>Thank you for applying! We'll be in touch soon.</p>
                </div>
              )}
              {submitStatus === "error" && (
                <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-center justify-center space-x-2">
                  <AlertCircle className="w-5 h-5" />
                  <p>Error submitting application. Please try again.</p>
                </div>
              )}
            </form>
          </div>
        </div>

        {/* Benefits Section */}
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-8 text-gray-900">
            Beta Tester Benefits
          </h2>
          <div className="grid md:grid-cols-3 gap-6">
            {[
              {
                icon: <Star className="w-12 h-12 text-blue-600 mb-4" />,
                title: "Free Access",
                description:
                  "Get 3 months of free access to all premium features",
              },
              {
                icon: <Brain className="w-12 h-12 text-blue-600 mb-4" />,
                title: "Early Access",
                description:
                  "Be the first to try new features and improvements",
              },
              {
                icon: <CheckCircle className="w-12 h-12 text-blue-600 mb-4" />,
                title: "Lifetime Discount",
                description: "Get 50% off the regular price after beta period",
              },
            ].map((benefit, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-md transition-all duration-300 
                  hover:shadow-xl hover:transform hover:-translate-y-1 cursor-pointer
                  border border-transparent hover:border-blue-100"
              >
                <div className="flex flex-col items-center text-center">
                  {benefit.icon}
                  <h3 className="font-semibold text-xl text-gray-900 mb-2">
                    {benefit.title}
                  </h3>
                  <p className="text-gray-600">{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-300 py-8">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <p className="text-sm">© 2024 UPangea Inc. All rights reserved.</p>
            <div className="mt-2 space-x-4">
              <Link
                to="/privacy"
                className="text-sm hover:text-white transition-colors"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms"
                className="text-sm hover:text-white transition-colors"
              >
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
