import React, { useState, useEffect, useCallback, useContext } from "react";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "../components/ui/card";
import { Label } from "../components/ui/label";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "../components/ui/dialog";
import { Input } from "../components/ui/input";
import {
  Lock,
  Unlock,
  Menu,
  BookOpen,
  ChevronDown,
  ChevronRight,
  Plus,
  AlertCircle,
  BarChart2,
  ClipboardList,
  X,
  Settings,
  CreditCard,
  User,
  CheckCircle,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import logoImage from "../assets/images/5.svg";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import PremiumCreateTest from "../components/PremiumCreateTest";
import { SessionContext } from "../index";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Add this with other constants at the top of the file, before the Dashboard component
const QUESTION_COUNT_INCREMENTS = [
  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 53, 59,
];

const unavailableSubjects = [
  {
    name: "Behavioral Sciences",
    totalQuestions: 0,
    subCategories: [],
    isLocked: true,
    isChecked: false,
  },
  {
    name: "Biochemistry",
    totalQuestions: 0,
    subCategories: [],
    isLocked: true,
    isChecked: false,
  },
  {
    name: "Biology",
    totalQuestions: 0,
    subCategories: [],
    isLocked: true,
    isChecked: false,
  },
  {
    name: "General Chemistry",
    totalQuestions: 0,
    subCategories: [],
    isLocked: true,
    isChecked: false,
  },
  {
    name: "Organic Chemistry",
    totalQuestions: 0,
    subCategories: [],
    isLocked: true,
    isChecked: false,
  },
  {
    name: "Physics",
    totalQuestions: 0,
    subCategories: [],
    isLocked: true,
    isChecked: false,
  },
];

const ALL_SUBJECTS = [
  "Critical Analysis & Reasoning Skills",
  "Biology",
  "Chemistry",
  "Physics",
  "Psychology",
  "Sociology",
  "Biochemistry",
];

const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

function BetaSignupPopup({ isOpen, setIsOpen, session }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    currentScore: "",
    targetScore: "",
    studyHours: "",
    testDate: "",
    prepResources: "",
    why: "",
  });
  const [submitStatus, setSubmitStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasExistingApplication, setHasExistingApplication] = useState(false);
  const [existingApplicationData, setExistingApplicationData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Check for existing application when popup opens
  useEffect(() => {
    const checkExistingApplication = async () => {
      if (!isOpen || !session?.user?.email) return;

      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from("beta_applications")
          .select("*")
          .eq("email", session.user.email)
          .single();

        if (error && error.code !== "PGRST116") {
          // PGRST116 is the "no rows returned" error
          console.error("Error checking application:", error);
          throw error;
        }

        if (data) {
          setHasExistingApplication(true);
          setExistingApplicationData(data);
        } else {
          setHasExistingApplication(false);
          setExistingApplicationData(null);
        }
      } catch (error) {
        console.error("Error:", error);
        setErrorMessage("Error checking application status");
      } finally {
        setIsLoading(false);
      }
    };

    checkExistingApplication();
  }, [isOpen, session?.user?.email]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus(null);

    // Prevent submission if already applied
    if (hasExistingApplication) {
      setErrorMessage("You have already submitted a beta application");
      setSubmitStatus("error");
      return;
    }

    try {
      console.log("Submitting form data:", formData);

      const userEmail = session?.user?.email || formData.email;

      const { data, error } = await supabase
        .from("beta_applications")
        .insert([
          {
            name: formData.name,
            email: userEmail,
            current_score: parseInt(formData.currentScore) || null,
            target_score: parseInt(formData.targetScore),
            study_hours: parseFloat(formData.studyHours),
            test_date: formData.testDate,
            prep_resources: formData.prepResources,
            why: formData.why,
          },
        ])
        .select();

      if (error) {
        console.error("Supabase error details:", error);
        throw error;
      }

      console.log("Successfully submitted application:", data);
      setSubmitStatus("success");
      setFormData({
        name: "",
        email: "",
        currentScore: "",
        targetScore: "",
        studyHours: "",
        testDate: "",
        prepResources: "",
        why: "",
      });

      setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    } catch (error) {
      console.error("Detailed submission error:", error);
      setSubmitStatus("error");
      setErrorMessage(
        error.message || "Error submitting application. Please try again.",
      );
    }
  };

  if (!isOpen) return null;

  // Loading state
  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Checking application status...</p>
        </div>
      </div>
    );
  }

  // Already submitted state
  if (hasExistingApplication) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg shadow-xl max-w-md w-full relative">
          <button
            onClick={() => setIsOpen(false)}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
          >
            <X className="h-6 w-6" />
          </button>
          <div className="p-8">
            <div className="text-center">
              <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
              <h2 className="text-2xl font-bold mb-4 text-gray-900">
                Application Already Submitted
              </h2>
              <p className="text-gray-600 mb-6">
                You have already applied to join our beta program on{" "}
                {new Date(
                  existingApplicationData.created_at,
                ).toLocaleDateString()}
              </p>
              <div className="bg-blue-50 border border-blue-100 rounded-lg p-4 mb-6 text-left">
                <h3 className="font-semibold text-blue-900 mb-2">
                  Your Application Details:
                </h3>
                <ul className="space-y-2 text-blue-800">
                  <li>
                    <span className="font-medium">Name:</span>{" "}
                    {existingApplicationData.name}
                  </li>
                  <li>
                    <span className="font-medium">Target Score:</span>{" "}
                    {existingApplicationData.target_score}
                  </li>
                  <li>
                    <span className="font-medium">Study Hours/Week:</span>{" "}
                    {existingApplicationData.study_hours}
                  </li>
                </ul>
              </div>
              <Button
                onClick={() => setIsOpen(false)}
                className="w-full bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Regular form state
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full relative max-h-[90vh] overflow-y-auto">
        <button
          onClick={() => setIsOpen(false)}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X className="h-6 w-6" />
        </button>
        <div className="p-8">
          <h2 className="text-2xl font-bold mb-6 text-blue-600">
            Join Our Beta Program
          </h2>
          <p className="text-gray-600 mb-6">
            Get exclusive access to premium features and help shape the future
            of UPangea.
          </p>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Label htmlFor="name">Full Name</Label>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your full name"
                  required
                  className="mt-1"
                />
              </div>

              <div>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="your@email.com"
                  required
                  className="mt-1"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Label htmlFor="currentScore">Current MCAT Score</Label>
                <Input
                  id="currentScore"
                  name="currentScore"
                  type="number"
                  value={formData.currentScore}
                  onChange={handleChange}
                  placeholder="Current score"
                  className="mt-1"
                />
              </div>

              <div>
                <Label htmlFor="targetScore">Target Score</Label>
                <Input
                  id="targetScore"
                  name="targetScore"
                  type="number"
                  value={formData.targetScore}
                  onChange={handleChange}
                  placeholder="Target score"
                  required
                  className="mt-1"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Label htmlFor="studyHours">Study Hours/Week</Label>
                <Input
                  id="studyHours"
                  name="studyHours"
                  type="number"
                  value={formData.studyHours}
                  onChange={handleChange}
                  placeholder="Hours per week"
                  required
                  className="mt-1"
                />
              </div>

              <div>
                <Label htmlFor="testDate">Planned Test Date</Label>
                <Input
                  id="testDate"
                  name="testDate"
                  type="date"
                  value={formData.testDate}
                  onChange={handleChange}
                  required
                  className="mt-1"
                />
              </div>
            </div>

            <div>
              <Label htmlFor="prepResources">Current MCAT Prep Resources</Label>
              <textarea
                id="prepResources"
                name="prepResources"
                value={formData.prepResources}
                onChange={handleChange}
                placeholder="What MCAT prep resources are you currently using?"
                className="w-full min-h-[80px] px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <Label htmlFor="why">
                Why do you want to join our beta program?
              </Label>
              <textarea
                id="why"
                name="why"
                value={formData.why}
                onChange={handleChange}
                placeholder="Tell us why you'd be a great beta tester..."
                required
                className="w-full min-h-[80px] px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <Button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-600 to-green-600 text-white 
                hover:from-blue-700 hover:to-green-700 transition-all duration-300"
            >
              Apply for Beta Access
            </Button>

            {submitStatus === "success" && (
              <div className="bg-green-50 text-green-700 p-4 rounded-lg flex items-center justify-center space-x-2">
                <CheckCircle className="w-5 h-5" />
                <p>Thank you for applying! We'll be in touch soon.</p>
              </div>
            )}
            {submitStatus === "error" && (
              <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-center justify-center space-x-2">
                <AlertCircle className="w-5 h-5" />
                <p>{errorMessage}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default function Dashboard() {
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const stripe_options = {
    // passing the client secret obtained from the server
    clientSecret: "{{CLIENT_SECRET}}",
  };

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    navigate("/");
  };

  const handleSubjectCardClick = (subject) => {
    const isPremium = subject !== "Critical Analysis & Reasoning Skills";
    const subjectData = performanceData.find(
      (data) => data.subject === subject,
    ) || {
      score: 0,
      total_questions_answered: 0,
      correct_answers: 0,
    };

    if (
      (isPremium && currentPlan !== "Premium") ||
      subjectData.total_questions_answered === 0
    ) {
      return;
    }

    setSelectedPerformanceSubject(subject);
    setIsPerformanceChartOpen(true);
  };

  const [subjects, setSubjects] = useState([]);
  const [passageTypes, setPassageTypes] = useState([
    { questions: 5, count: 0, available: 0 },
    { questions: 6, count: 0, available: 0 },
    { questions: 7, count: 0, available: 0 },
  ]);
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [activeTab, setActiveTab] = useState("create");
  const [isSubjectSelected, setIsSubjectSelected] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);
  const [previousTests, setPreviousTests] = useState([]);
  const [carsSubject, setCarsSubject] = useState(null);
  const [isQBankExpanded, setIsQBankExpanded] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isResetting, setIsResetting] = useState(false);
  const [isResetConfirmOpen, setIsResetConfirmOpen] = useState(false);
  const [isResetTypeConfirmOpen, setIsResetTypeConfirmOpen] = useState(false);
  const [resetConfirmText, setResetConfirmText] = useState("");
  const [isPremiumPopupOpen, setIsPremiumPopupOpen] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("Free");

  // New state variables for performance chart
  const [selectedPerformanceSubject, setSelectedPerformanceSubject] =
    useState(null);
  const [isPerformanceChartOpen, setIsPerformanceChartOpen] = useState(false);

  // after AI
  const [userId, setUserId] = useState(null);
  const [isUserInitialized, setIsUserInitialized] = useState(false);
  const [questionCount, setQuestionCount] = useState(0);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [testTopics, setTestTopics] = useState({});
  const [testQuestions, setTestQuestions] = useState({});

  // First, let's add loading states
  const [isLoadingTestData, setIsLoadingTestData] = useState(true);

  // Add these new state variables
  const [availablePassages, setAvailablePassages] = useState([]);

  // Add these state variables
  const [selectedPassageCounts, setSelectedPassageCounts] = useState({
    5: 0, // 5-question passages
    6: 0, // 6-question passages
    7: 0, // 7-question passages
  });

  const showErrorMessage = (message) => {
    setErrorMessage(message);
    setIsErrorModalOpen(true);
  };

  useEffect(() => {
    if (!session) navigate("/");
  }, [session]);

  const togglePlan = () => {
    setIsPremiumPopupOpen(!isPremiumPopupOpen);
  };

  useEffect(() => {
    const fetchUserPlan = async () => {
      if (!session) return;

      try {
        // Get the Supabase user ID

        const supabaseUserId = session.user.id;
        setUserId(supabaseUserId);

        // Fetch the user's current plan
        const { data: planData, error: planError } = await supabase
          .from("user_profiles")
          .select("plan")
          .eq("user_id", supabaseUserId)
          .single();

        if (planError) throw planError;

        setCurrentPlan(planData.plan || "Free");
      } catch (error) {
        console.error("Error fetching user plan:", error);
        showErrorMessage("Error fetching user plan");
      }
    };

    fetchUserPlan();
  }, [session]);

  useEffect(() => {
    const initializeUser = async () => {
      if (session && !isUserInitialized) {
        // Check if user mapping exists
        const { data: mappingData, error: mappingError } = await supabase
          .from("user_profiles")
          .select("id")
          .eq("user_id", session.user.id)
          .single();

        if (mappingError && mappingError.code !== "PGRST116") {
          console.error("Error checking user mapping:", mappingError);
          return;
        }

        if (!mappingData) {
          // Create new user in 'users' table
          const { error: userError } = await supabase
            .from("user_profiles")
            .insert({ email: session.user.email, plan: "Free" })
            .select()
            .single();
          if (userError) {
            console.error("Error creating user:", userError);
            return;
          }
          setIsUserInitialized(true);
          console.log("User initialized successfully");
        }
      }
    };

    initializeUser();
  }, [session]);

  useEffect(() => {
    const fetchSession = async () => {
      // Instead of a mock user ID, use the authenticated user's ID
      if (session) {
        // Fetch performance data
        const { data: performanceData, error: performanceError } =
          await supabase
            .from("performance_cars")
            .select(
              "id, user_id, subject, total_questions_answered, correct_answers, score",
            )
            .eq("user_id", session.user.id);

        if (performanceError) {
          console.error("Performance data error:", performanceError);
        } else {
          // console.log("Fetched performance data:", performanceData);
          setPerformanceData(performanceData || []);
        }
        // Fetch previous tests
        const { data: testsData, error: testsError } = await supabase
          .from("tests_new")
          .select("id, user_id, test_date, total_questions, score")
          .eq("user_id", session.user.id)
          .order("test_date", { ascending: false });

        if (testsError) {
          console.error("Tests data error:", testsError);
        } else {
          // console.log("Fetched tests data:", testsData);
          setPreviousTests(testsData || []);
        }
      }

      // Fetch the total number of CARS questions

      const { data: previousTests, error: previousTestsError } = await supabase
        .from("tests_new")
        .select("passage_ids")
        .eq("user_id", session.user.id);
      if (previousTestsError)
        console.error("Error fetching previous tests:", previousTestsError);
      const completedPassageIds = previousTests.flatMap(
        (test) => test.passage_ids || [],
      );

      const { data: questionData, error: questionError } = await supabase
        .from("passages")
        .select("id, topic, question_count")
        .eq("subject", "Critical Analysis & Reasoning Skills")
        .not("id", "in", `(${completedPassageIds.join(",")})`);
      if (questionError)
        console.error("Error fetching question count:", questionError);

      if (!questionData) {
        console.error("Error fetching question count:", questionError);
      } else {
        const totalQuestionsForSubject = questionData.reduce(
          (sum, row) => sum + row.question_count,
          0,
        );
        const totalQuestionsHumanities = questionData
          .filter((row) => row.topic === "Humanities")
          .reduce((sum, row) => sum + row.question_count, 0);
        const totalQuestionsSocialSciences = questionData
          .filter((row) => row.topic === "Social Sciences")
          .reduce((sum, row) => sum + row.question_count, 0);
        setCarsSubject({
          name: "Critical Analysis & Reasoning Skills",
          totalQuestions: totalQuestionsForSubject,
          subCategories: [
            {
              name: "Humanities",
              isChecked:
                carsSubject?.subCategories?.find(
                  (sub) => sub.name === "Humanities",
                )?.isChecked ?? false,
              questions: totalQuestionsHumanities,
            },
            {
              name: "Social Sciences",
              isChecked:
                carsSubject?.subCategories?.find(
                  (sub) => sub.name === "Social Sciences",
                )?.isChecked ?? false,
              questions: totalQuestionsSocialSciences,
            },
          ],
          isLocked: false,
          isChecked: carsSubject?.isChecked ?? false,
        });
      }

      // Fetch available passage types
      // const { data: passageData, error: passageError } = await supabase
      //   .from("passages")
      //   .select("id, questions!inner(id)")
      //   .order("id");

      // if (passageError) {
      //   console.error("Error fetching passage data:", passageError);
      // } else {
      //   const availablePassageTypes = passageData.reduce((acc, passage) => {
      //     const questionCount = passage.questions.length;
      //     acc[questionCount] = (acc[questionCount] || 0) + 1;
      //     return acc;
      //   }, {});

      //   setPassageTypes([
      //     { questions: 5, count: 0, available: availablePassageTypes[5] || 0 },
      //     { questions: 6, count: 0, available: availablePassageTypes[6] || 0 },
      //     { questions: 7, count: 0, available: availablePassageTypes[7] || 0 },
      //   ]);
      // }
    };

    fetchSession();
  }, [session]); // Add user to the dependency array

  useEffect(() => {
    if (subjects && carsSubject) {
      // TODO: subjects sometimes is empty! need to figure out why. right after ending a test
      const humanities = subjects[0]?.subCategories[0];
      const socialSciences = subjects[0]?.subCategories[1];
      let total =
        (humanities.isChecked ? humanities.questions : 0) +
        (socialSciences.isChecked ? socialSciences.questions : 0);
      setQuestionCount(total > 59 ? 59 : total);
    }
  }, [subjects, selectedTopics]);

  useEffect(() => {
    if (carsSubject) {
      setSubjects([carsSubject]);
    }
  }, [carsSubject]);

  useEffect(() => {
    const totalCount = passageTypes.reduce((sum, type) => sum + type.count, 0);
    if (totalCount > 0) {
      setSubjects((prevSubjects) =>
        prevSubjects.map((subject) => ({
          ...subject,
          isChecked: subject.subCategories.some(
            (subCategory) => subCategory.isChecked,
          ),
        })),
      );
    }
  }, [passageTypes]);

  useEffect(() => {
    const hasSelectedSubject = subjects.some(
      (subject) =>
        subject.isChecked ||
        subject.subCategories.some((subCategory) => subCategory.isChecked),
    );
    setIsSubjectSelected(hasSelectedSubject);
  }, [subjects]);

  useEffect(() => {
    const fetchTestDetails = async () => {
      if (!previousTests.length) return;

      const topicsMap = {};
      const questionsMap = {};

      for (const test of previousTests) {
        if (!test.passage_ids || !test.passage_ids.length) continue;

        const { data, error } = await supabase
          .from("passages")
          .select("topic, question_count")
          .in("id", test.passage_ids);

        if (error) {
          console.error("Error fetching test details:", error);
          continue;
        }

        const topics = [...new Set(data.map((p) => p.topic))];
        const totalQuestions = data.reduce(
          (sum, passage) => sum + passage.question_count,
          0,
        );

        topicsMap[test.id] = topics;
        questionsMap[test.id] = totalQuestions;
      }

      setTestTopics(topicsMap);
      setTestQuestions(questionsMap);
    };

    fetchTestDetails();
  }, [previousTests]);

  const fetchUserData = async () => {
    if (!session) return;

    try {
      setIsLoadingTestData(true);
      const supabaseUserId = session.user.id;

      // Fetch all test data in a single query
      const { data: testsData, error: testsError } = await supabase
        .from("tests_new")
        .select(
          `
          id,
          user_id,
          test_date,
          total_questions,
          score,
          passage_ids
        `,
        )
        .eq("user_id", supabaseUserId)
        .order("test_date", { ascending: false });

      if (testsError) throw testsError;

      // Only update state if we have valid data
      if (testsData) {
        setPreviousTests(testsData);

        // Calculate CARS performance from completed tests only
        const completedTests = testsData.filter(
          (test) => test.score !== null && test.total_questions > 0,
        );

        if (completedTests.length > 0) {
          const totalQuestions = completedTests.reduce(
            (sum, test) => sum + (test.total_questions || 0),
            0,
          );

          const weightedScore = completedTests.reduce(
            (sum, test) =>
              sum + (test.score || 0) * (test.total_questions || 0),
            0,
          );

          const averageScore = Math.round(weightedScore / totalQuestions);

          const carsPerformance = {
            subject: "Critical Analysis & Reasoning Skills",
            total_questions_answered: totalQuestions,
            correct_answers: Math.round((averageScore / 100) * totalQuestions),
            score: averageScore,
          };

          setPerformanceData([carsPerformance]);
        }

        // Process test topics and questions in a single loop
        const topicsMap = {};
        const questionsMap = {};

        // Batch fetch all passage data for efficiency
        const allPassageIds = testsData
          .flatMap((test) => test.passage_ids || [])
          .filter(Boolean);

        if (allPassageIds.length > 0) {
          const { data: passageData } = await supabase
            .from("passages")
            .select("id, topic, question_count")
            .in("id", allPassageIds);

          if (passageData) {
            // Create a map for quick passage lookups
            const passageMap = new Map(
              passageData.map((passage) => [passage.id, passage]),
            );

            // Process each test
            testsData.forEach((test) => {
              if (test.passage_ids && test.passage_ids.length) {
                const testPassages = test.passage_ids
                  .map((id) => passageMap.get(id))
                  .filter(Boolean);

                topicsMap[test.id] = [
                  ...new Set(testPassages.map((p) => p.topic)),
                ];
                questionsMap[test.id] = testPassages.reduce(
                  (sum, p) => sum + p.question_count,
                  0,
                );
              }
            });
          }
        }

        setTestTopics(topicsMap);
        setTestQuestions(questionsMap);
      }
    } catch (error) {
      console.error("Error fetching user data:", error.message);
      // Only show error for non-initialization errors
      if (error.message !== "FetchError: Failed to fetch") {
        showErrorMessage("Error fetching user data");
      }
    } finally {
      setIsLoadingTestData(false);
    }
  };

  // Update the initialization useEffect to prevent multiple fetches
  useEffect(() => {
    let isMounted = true;

    const initializeData = async () => {
      if (session && isMounted) {
        await fetchUserData();
      }
    };

    initializeData();

    return () => {
      isMounted = false;
    };
  }, [session]); // Only depend on session

  // Remove the test_answers_cars subscription since we don't need it anymore
  // Remove or update other subscriptions that might trigger unnecessary refreshes
  useEffect(() => {
    if (!session) return;

    const subscription = supabase
      .channel("test_updates")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "tests_new",
          filter: `user_id=eq.${session.user.id}`,
        },
        (payload) => {
          // Only fetch if we have a completed test update
          if (
            payload.new &&
            payload.new.user_id === session.user.id &&
            payload.new.score !== null
          ) {
            fetchUserData();
          }
        },
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [session]);

  const toggleSubject = (subjectName) => {
    setSubjects((prevSubjects) => {
      return prevSubjects.map((subject) => {
        if (subject.name === subjectName) {
          const newIsChecked = !subject.isChecked;
          return {
            ...subject,
            isChecked: newIsChecked,
            subCategories: subject.subCategories.map((subCategory) => ({
              ...subCategory,
              isChecked: newIsChecked,
            })),
          };
        }
        return subject;
      });
    });

    setSelectedSubjects((prev) =>
      prev.includes(subjectName)
        ? prev.filter((s) => s !== subjectName)
        : [...prev, subjectName],
    );
    setSelectedTopics((prev) =>
      prev.length === 0 ? ["Humanities", "Social Sciences"] : [],
    );

    // const subject = subjects.find((s) => s.name === subjectName);
    // const newTopics = subject.topics.map((t) => t.name);
    // setSelectedTopics((prev) =>
    //   prev.some((t) => newTopics.includes(t))
    //     ? prev.filter((t) => !newTopics.includes(t))
    //     : [...prev, ...newTopics],
    // );
  };

  const toggleSubCategory = (subjectName, subCategoryName) => {
    setSubjects((prevSubjects) => {
      return prevSubjects.map((subject) => {
        if (subject.name === subjectName) {
          const updatedSubCategories = subject.subCategories.map(
            (subCategory) =>
              subCategory.name === subCategoryName
                ? { ...subCategory, isChecked: !subCategory.isChecked }
                : subCategory,
          );
          return {
            ...subject,
            isChecked: updatedSubCategories.some(
              (subCategory) => subCategory.isChecked,
            ),
            subCategories: updatedSubCategories,
          };
        }
        return subject;
      });
    });

    // Update selected topics
    setSelectedTopics((prev) => {
      const newTopics = prev.includes(subCategoryName)
        ? prev.filter((s) => s !== subCategoryName)
        : [...prev, subCategoryName];

      // If no topics are selected, reset available passages
      if (newTopics.length === 0) {
        setAvailablePassages([]);
        setSelectedPassageCounts({
          5: 0,
          6: 0,
          7: 0,
        });
      }

      return newTopics;
    });

    setSelectedSubjects((prev) =>
      prev.length === 0 ? ["Critical Analysis & Reasoning Skills"] : [],
    );
  };

  const handleResetConfirm = () => {
    setIsResetConfirmOpen(false);
    setIsResetTypeConfirmOpen(true);
  };

  const handleResetTypeConfirm = () => {
    if (resetConfirmText.toUpperCase() === "RESET") {
      setIsResetTypeConfirmOpen(false);
      handleResetData();
    }
  };

  const handleResetData = async () => {
    try {
      setIsResetting(true);

      const supabaseUserId = session.user.id;

      // Delete entries in 'tests' table
      const { error: deleteTestsError } = await supabase
        .from("tests_new")
        .delete()
        .eq("user_id", supabaseUserId);

      if (deleteTestsError) throw deleteTestsError;

      // Delete entries in 'performance' table
      const { error: deletePerformanceError } = await supabase
        .from("performance_cars")
        .delete()
        .eq("user_id", supabaseUserId);

      if (deletePerformanceError) throw deletePerformanceError;

      // Delete entries in 'test_progress' table
      const { error: deleteTestProgressError } = await supabase
        .from("test_progress")
        .delete()
        .eq("user_id", supabaseUserId);

      if (deleteTestProgressError) throw deleteTestProgressError;

      // Reset all relevant state
      setPreviousTests([]);
      setPerformanceData([]);
      setAvailablePassages([]);
      setSelectedPassageCounts({
        5: 0,
        6: 0,
        7: 0,
      });

      // Short timeout to ensure user sees the loading state
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error("Error resetting data:", error);
      console.error("Error", "An error occurred while resetting your data.");
      setIsResetting(false);
    }
  };

  const renderSubjects = (subject) =>
    subject && (
      <Card
        key={subject.name}
        className="m-4 transition-all duration-200 hover:shadow-md"
      >
        <CardHeader className="pb-2">
          <CardTitle className="text-lg">
            <div className="p-3 bg-gray-50 rounded-t-lg border-b">
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  className="w-4 h-4 mr-3 shrink-0 cursor-pointer accent-blue-600"
                  checked={subject.isChecked}
                  onChange={() => toggleSubject(subject.name)}
                  disabled={subject.totalQuestions === 0}
                />
                <span className="font-medium text-lg">
                  {" "}
                  {/* Increased subject font size */}
                  {subject.name}
                </span>
              </div>
              <div className="flex items-center justify-end space-x-3 ml-7">
                <div className="bg-white px-3 py-1 rounded-full border text-sm text-gray-600">
                  {subject.totalQuestions} questions
                </div>
                {subject.isLocked ? (
                  <Lock className="w-4 h-4 text-gray-500" />
                ) : (
                  <Unlock className="w-4 h-4 text-teal-500" />
                )}
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent className="pt-2">
          <div className="space-y-1">
            {subject.subCategories.map((subCategory) => (
              <div
                key={subCategory.name}
                className="flex items-center justify-between p-3 hover:bg-gray-50 transition-colors duration-200 rounded-md"
              >
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-4 h-4 mr-3 cursor-pointer accent-blue-600"
                    checked={subCategory.isChecked}
                    onChange={() =>
                      toggleSubCategory(subject.name, subCategory.name)
                    }
                    disabled={subCategory.questions === 0}
                  />
                  <span className="text-base font-medium">
                    {" "}
                    {/* Increased topic font size and added font-medium */}
                    {subCategory.name}
                  </span>
                </div>
                <div className="text-sm text-gray-600 bg-gray-50 px-3 py-1 rounded-full">
                  {subCategory.questions} questions
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    );

  const handleCreateTest = async () => {
    if (!session) {
      setIsLoginDialogOpen(true);
      return;
    }

    try {
      const supabaseUserId = session.user.id;

      // Get available passages
      const { data: previousTests } = await supabase
        .from("tests_new")
        .select("passage_ids")
        .eq("user_id", supabaseUserId);

      const completedPassageIds =
        previousTests?.flatMap((test) => test.passage_ids || []) || [];

      // Get passages for each question count
      const selectedPassages = [];

      for (const [questionCount, numPassages] of Object.entries(
        selectedPassageCounts,
      )) {
        if (numPassages > 0) {
          const query = supabase
            .from("passages")
            .select("id")
            .eq("subject", "Critical Analysis & Reasoning Skills")
            .in("topic", selectedTopics)
            .not("id", "in", `(${completedPassageIds.join(",")})`)
            .limit(numPassages);

          // Special handling for 5-question group to include 4-question passages
          if (questionCount === "5") {
            query.in("question_count", [4, 5]);
          } else {
            query.eq("question_count", parseInt(questionCount));
          }

          const { data: passages } = await query;

          if (!passages || passages.length < numPassages) {
            showErrorMessage(
              `Not enough ${questionCount}-question passages available.`,
            );
            return;
          }

          selectedPassages.push(...passages.map((p) => p.id));
        }
      }

      const totalQuestions = Object.entries(selectedPassageCounts).reduce(
        (sum, [questionCount, passages]) => sum + questionCount * passages,
        0,
      );

      const testData = {
        total_questions: totalQuestions,
        passage_ids: selectedPassages,
        user_id: supabaseUserId,
      };

      const { data, error } = await supabase
        .from("tests_new")
        .insert(testData)
        .select()
        .single();

      if (error) throw error;

      await fetchUserData();
      navigate(`/test/${data.id}`);
    } catch (error) {
      console.error("Error creating test:", error);
      showErrorMessage("Error creating test. Please try again.");
    }
  };

  const handleCreateTestClick = () => {
    setActiveTab("create");
  };

  const toggleQBank = () => {
    setIsQBankExpanded(!isQBankExpanded);
  };

  const hasAvailableQuestions = useCallback(() => {
    return subjects.some((subject) => subject.totalQuestions > 0);
  }, [subjects]);

  // Function to get chart data for a subject
  const getChartData = (subjectName) => {
    const filteredTests = previousTests
      .sort((a, b) => new Date(a.test_date) - new Date(b.test_date))
      .map((test, index) => ({
        date: new Date(test.test_date).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
        score: test.score || 0,
        testNumber: index + 1,
        questions: testQuestions[test.id] || 0,
        topics: testTopics[test.id] ? testTopics[test.id].join(", ") : "",
      }));

    return filteredTests;
  };

  // Add this useEffect back to initialize the data
  useEffect(() => {
    let isMounted = true;

    const initializeData = async () => {
      if (session && isMounted) {
        await fetchUserData();
      }
    };

    initializeData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [session]); // Add session as dependency

  // Add this function to fetch and analyze available passages
  const analyzeAvailablePassages = async () => {
    if (!session || !selectedTopics.length) {
      setAvailablePassages([]);
      return;
    }

    try {
      // First get all completed passages
      const { data: previousTests, error: testsError } = await supabase
        .from("tests_new")
        .select("passage_ids")
        .eq("user_id", session.user.id);

      if (testsError) throw testsError;

      // Combine all completed passage IDs
      const completedPassageIds =
        previousTests?.flatMap((test) => test.passage_ids || []) || [];

      // Fetch available passages with a more robust query
      const query = supabase
        .from("passages")
        .select("id, question_count, topic")
        .eq("subject", "Critical Analysis & Reasoning Skills")
        .in("topic", selectedTopics);

      // Only filter by completed passages if there are any
      if (completedPassageIds.length > 0) {
        query.not("id", "in", `(${completedPassageIds.join(",")})`);
      }

      const { data: passages, error: passagesError } = await query;

      if (passagesError) throw passagesError;

      // Reset passage counts when available passages change
      setAvailablePassages(passages || []);

      // Reset selected passage counts when available passages change
      setSelectedPassageCounts({
        5: 0,
        6: 0,
        7: 0,
      });
    } catch (error) {
      console.error("Error analyzing passages:", error);
      setAvailablePassages([]);
    }
  };

  // Add useEffect to trigger passage analysis when topics or tests change
  useEffect(() => {
    if (session && selectedTopics.length > 0) {
      analyzeAvailablePassages();
    }
  }, [session, selectedTopics, previousTests.length]); // Add previousTests.length as dependency

  // Update the renderQuestionCountSelector function
  const renderQuestionCountSelector = () => {
    const MAX_QUESTIONS = 53;

    // Helper function to get available count for a passage group
    const getAvailableCount = (mainQuestionCount) => {
      return availablePassages.filter((p) =>
        // Include 4-question passages in the 5-question group
        mainQuestionCount === 5
          ? p.question_count === 4 || p.question_count === 5
          : p.question_count === mainQuestionCount,
      ).length;
    };

    // Helper function to get actual questions that would be added
    const getActualQuestionCount = (mainQuestionCount, numPassages) => {
      if (mainQuestionCount !== 5) return mainQuestionCount * numPassages;

      // For 5-question group, calculate actual questions based on available passages
      const fourQuestionPassages = availablePassages.filter(
        (p) => p.question_count === 4,
      );
      const fiveQuestionPassages = availablePassages.filter(
        (p) => p.question_count === 5,
      );

      // Prioritize 5-question passages, use 4-question passages if needed
      const numFiveQ = Math.min(numPassages, fiveQuestionPassages.length);
      const numFourQ = Math.min(
        numPassages - numFiveQ,
        fourQuestionPassages.length,
      );

      return numFiveQ * 5 + numFourQ * 4;
    };

    return (
      <div className="space-y-6">
        <div className="bg-blue-50 border-l-4 border-blue-500 p-4">
          <div className="flex items-center">
            <AlertCircle className="h-5 w-5 text-blue-400 mr-2 flex-shrink-0" />
            <div className="flex-grow">
              <p className="text-sm text-blue-700">
                Select how many passages you want of each type (max{" "}
                {MAX_QUESTIONS} questions total)
              </p>
            </div>
          </div>
        </div>

        <div className="grid gap-4">
          {[5, 6, 7].map((questionCount) => {
            const availableCount = getAvailableCount(questionCount);
            const currentTotal = Object.entries(selectedPassageCounts).reduce(
              (sum, [count, num]) =>
                sum + getActualQuestionCount(Number(count), num),
              0,
            );
            const remainingQuestions = MAX_QUESTIONS - currentTotal;
            const maxMorePassages = Math.floor(
              remainingQuestions / questionCount,
            );
            const effectiveMaxPassages = Math.min(
              availableCount,
              maxMorePassages + selectedPassageCounts[questionCount],
            );

            return (
              <div
                key={questionCount}
                className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm"
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="font-medium text-gray-700">
                    {questionCount}-Question Passages {/* Simplified label */}
                  </span>
                  <span className="text-sm text-gray-500">
                    {availableCount} available
                  </span>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() =>
                      setSelectedPassageCounts((prev) => ({
                        ...prev,
                        [questionCount]: Math.max(0, prev[questionCount] - 1),
                      }))
                    }
                    className="w-8 h-8 rounded-full bg-gray-100 hover:bg-gray-200 flex items-center justify-center text-gray-600"
                    disabled={selectedPassageCounts[questionCount] === 0}
                  >
                    -
                  </button>
                  <span className="w-8 text-center font-medium">
                    {selectedPassageCounts[questionCount]}
                  </span>
                  <button
                    onClick={() =>
                      setSelectedPassageCounts((prev) => ({
                        ...prev,
                        [questionCount]: Math.min(
                          effectiveMaxPassages,
                          prev[questionCount] + 1,
                        ),
                      }))
                    }
                    className="w-8 h-8 rounded-full bg-gray-100 hover:bg-gray-200 flex items-center justify-center text-gray-600"
                    disabled={
                      selectedPassageCounts[questionCount] >=
                      effectiveMaxPassages
                    }
                  >
                    +
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm font-medium text-gray-700">
              Total Questions:
            </span>
            <span className="text-lg font-bold text-blue-600">
              {Object.entries(selectedPassageCounts).reduce(
                (sum, [count, num]) =>
                  sum + getActualQuestionCount(Number(count), num),
                0,
              )}
              /{MAX_QUESTIONS}
            </span>
          </div>
          <div className="h-2 bg-gray-200 rounded-full">
            <div
              className="h-full bg-gradient-to-r from-blue-600 to-teal-500 rounded-full transition-all duration-300"
              style={{
                width: `${
                  (Object.entries(selectedPassageCounts).reduce(
                    (sum, [count, num]) =>
                      sum + getActualQuestionCount(Number(count), num),
                    0,
                  ) /
                    MAX_QUESTIONS) *
                  100
                }%`,
              }}
            />
          </div>
        </div>

        {Object.values(selectedPassageCounts).some((count) => count > 0) && (
          <Button
            className="w-full bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white"
            onClick={handleCreateTest}
          >
            Create Test with{" "}
            {Object.entries(selectedPassageCounts).reduce(
              (sum, [count, num]) =>
                sum + getActualQuestionCount(Number(count), num),
              0,
            )}{" "}
            Questions
          </Button>
        )}
      </div>
    );
  };

  const renderPerformanceSection = () => {
    if (isLoadingTestData) {
      return (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {ALL_SUBJECTS.map((subject) => {
          const isPremium = subject !== "Critical Analysis & Reasoning Skills";
          const subjectData = performanceData.find(
            (data) => data.subject === subject,
          ) || {
            score: 0,
            total_questions_answered: 0,
            correct_answers: 0,
          };

          const correctAnswers = subjectData.correct_answers || 0;
          const totalAnswered = subjectData.total_questions_answered || 0;
          const incorrectAnswers = totalAnswered - correctAnswers;

          return (
            <Card
              key={subject}
              className={`p-6 hover:shadow-lg transition-shadow duration-300 ${
                (!isPremium || currentPlan === "Premium") && totalAnswered > 0
                  ? "cursor-pointer"
                  : "cursor-default"
              }`}
              onClick={() => handleSubjectCardClick(subject)}
            >
              <div className="flex items-center justify-between mb-4">
                <h4 className="text-xl font-semibold text-gray-800">
                  {subject === "Critical Analysis & Reasoning Skills"
                    ? "CARS"
                    : subject}
                </h4>
                <div className="w-20 h-20">
                  <CircularProgressbar
                    value={
                      isPremium && currentPlan !== "Premium"
                        ? 0
                        : subjectData.score || 0
                    }
                    text={`${
                      isPremium && currentPlan !== "Premium"
                        ? "--"
                        : subjectData.score || 0
                    }%`}
                    styles={buildStyles({
                      textSize: "22px",
                      pathColor: "#47F70D",
                      textColor: "#3e98c7",
                      trailColor: "#FFD6D7",
                    })}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-gray-600">
                  Question Total:{" "}
                  <span className="font-medium">
                    {isPremium && currentPlan !== "Premium"
                      ? "--"
                      : totalAnswered}
                  </span>
                </p>
                <div className="flex items-center space-x-2">
                  <div className="flex-1 bg-gray-200 rounded-full h-2 overflow-hidden">
                    <div
                      className="h-full bg-green-500"
                      style={{
                        width: `${
                          (isPremium && currentPlan !== "Premium") ||
                          totalAnswered === 0
                            ? 0
                            : (correctAnswers / totalAnswered) * 100
                        }%`,
                      }}
                    ></div>
                  </div>
                  <span className="text-sm font-medium text-green-600">
                    {isPremium && currentPlan !== "Premium"
                      ? "--"
                      : correctAnswers}
                  </span>
                  <span className="text-sm font-medium text-red-600">
                    {isPremium && currentPlan !== "Premium"
                      ? "--"
                      : incorrectAnswers}
                  </span>
                </div>
              </div>
              {isPremium && currentPlan !== "Premium" && (
                <div className="mt-4 p-2 bg-yellow-100 rounded-md">
                  <p className="text-sm text-yellow-700">
                    Upgrade to Premium for access to {subject} content!
                  </p>
                </div>
              )}
            </Card>
          );
        })}
      </div>
    );
  };

  // Update the previous tests section render logic
  const renderPreviousTestsSection = () => {
    if (isLoadingTestData) {
      return (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      );
    }

    if (!previousTests || previousTests.length === 0) {
      return (
        <Card className="text-center p-8 bg-gray-50">
          <div className="flex flex-col items-center">
            <ClipboardList className="w-16 h-16 text-gray-400 mb-4" />
            <h4 className="text-xl font-semibold text-gray-900 mb-2">
              No Tests Completed Yet
            </h4>
            <p className="text-gray-600 mb-6 max-w-md mx-auto">
              Start your MCAT CARS practice journey by taking your first test.
              Regular practice is key to improving your score!
            </p>
            <Button
              className="bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white px-8"
              onClick={handleCreateTestClick}
            >
              Create Your First Test
            </Button>
          </div>
        </Card>
      );
    }

    return (
      <div className="space-y-4">
        {previousTests.map((test, index) => {
          const topics = testTopics[test.id] || [];
          const questionCount = testQuestions[test.id] || 0;
          return (
            <Card
              key={`${test.id}-${questionCount}-${topics.join("-")}`}
              className="hover:shadow-md transition-shadow duration-200"
            >
              <CardContent className="p-6">
                <div className="flex items-center justify-between">
                  <div className="flex-1">
                    <div className="flex items-center space-x-3">
                      <h4 className="text-lg font-semibold text-blue-600">
                        CARS Test #{previousTests.length - index}
                      </h4>
                      {topics.map((topic) => (
                        <span
                          key={topic}
                          className="bg-blue-50 text-blue-700 text-xs px-2.5 py-0.5 rounded-full font-medium"
                        >
                          {topic}
                        </span>
                      ))}
                      {test.score >= 80 && (
                        <span className="bg-green-100 text-green-800 text-xs px-2.5 py-0.5 rounded-full font-medium">
                          Excellent
                        </span>
                      )}
                      {test.score >= 65 && test.score < 80 && (
                        <span className="bg-blue-100 text-blue-800 text-xs px-2.5 py-0.5 rounded-full font-medium">
                          Good
                        </span>
                      )}
                      {test.score < 65 && (
                        <span className="bg-yellow-100 text-yellow-800 text-xs px-2.5 py-0.5 rounded-full font-medium">
                          Needs Practice
                        </span>
                      )}
                    </div>
                    <div className="mt-2 grid grid-cols-2 gap-4">
                      <div>
                        <p className="text-sm text-gray-500">Date Taken</p>
                        <p className="font-medium text-gray-900">
                          {new Date(test.test_date).toLocaleDateString(
                            "en-US",
                            {
                              weekday: "short",
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            },
                          )}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Questions</p>
                        <p className="font-medium text-gray-900">
                          {questionCount} questions
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center px-6 border-l border-gray-200">
                    <div className="text-3xl font-bold text-gray-900">
                      {test.score || test.score === 0 ? `${test.score}%` : "0%"}
                    </div>
                    <p className="text-sm text-gray-500">Score</p>
                  </div>
                  <div className="flex flex-col items-end justify-center ml-6">
                    <Button
                      onClick={() => navigate(`/test/${test.id}/review`)}
                      className="bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white transition-all duration-300"
                    >
                      Review Test
                    </Button>
                    <p className="text-xs text-gray-500 mt-2">
                      Review answers and explanations
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex h-screen bg-gray-50">
      {isSidebarVisible && (
        <div className="w-64 bg-gradient-to-b from-blue-600 to-teal-500 text-white p-4 flex flex-col">
          <div className="flex flex-col items-center mb-6">
            <img
              src={logoImage}
              alt="UPangea Logo"
              className="h-20 w-20 mb-1"
            />
            <div className="text-center">
              <h1 className="text-4xl font-bold leading-none">UPangea</h1>
              <h2 className="text-xl text-white mt-2">MCAT</h2>
            </div>
          </div>
          <nav className="flex-grow">
            <h3 className="text-lg font-semibold mb-4 text-white">Dashboard</h3>
            <ul className="space-y-2">
              <li
                className="font-bold flex items-center mb-2 text-white cursor-pointer"
                onClick={toggleQBank}
              >
                {isQBankExpanded ? (
                  <ChevronDown className="w-5 h-5 mr-2" />
                ) : (
                  <ChevronRight className="w-5 h-5 mr-2" />
                )}
                <BookOpen className="w-5 h-5 mr-2" />
                QBank
              </li>
              {isQBankExpanded && (
                <>
                  <li>
                    <button
                      onClick={() => setActiveTab("create")}
                      className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                        activeTab === "create"
                          ? "bg-white text-blue-600 font-semibold"
                          : "text-white hover:bg-white hover:bg-opacity-20"
                      }`}
                    >
                      <Plus className="w-4 h-4 inline-block mr-2" />
                      Create Test
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setActiveTab("previous")}
                      className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                        activeTab === "previous"
                          ? "bg-white text-blue-600 font-semibold"
                          : "text-white hover:bg-white hover:bg-opacity-20"
                      }`}
                    >
                      <ClipboardList className="w-4 h-4 inline-block mr-2" />
                      Previous Tests
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setActiveTab("performance")}
                      className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                        activeTab === "performance"
                          ? "bg-white text-blue-600 font-semibold"
                          : "text-white hover:bg-white hover:bg-opacity-20"
                      }`}
                    >
                      <BarChart2 className="w-4 h-4 inline-block mr-2" />
                      Performance
                    </button>
                  </li>
                </>
              )}
            </ul>
          </nav>
          <div className="mt-auto">
            <Button
              variant="outline"
              className="w-full text-white border-white hover:bg-white hover:bg-opacity-20"
              onClick={() => setIsResetConfirmOpen(true)}
              disabled={isResetting}
            >
              {isResetting ? "Resetting..." : "Reset All Progress"}
            </Button>
          </div>
        </div>
      )}

      <div className="flex-1 flex flex-col">
        <header className="bg-white shadow-sm border-b border-gray-200">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <div className="flex items-center">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setIsSidebarVisible(!isSidebarVisible)}
                className="mr-2"
              >
                <Menu className="h-6 w-6" />
              </Button>
              <h2 className="text-xl font-semibold text-gray-700">
                {activeTab === "create"
                  ? "Create Test"
                  : activeTab === "previous"
                    ? "Previous Tests"
                    : "Performance"}
              </h2>
            </div>
            <div className="flex items-center space-x-4">
              {session && (
                <span className="text-sm font-medium text-gray-700">
                  Welcome, {session.user.email}
                </span>
              )}
              <Button
                variant="outline"
                onClick={() => setIsPremiumPopupOpen(true)}
              >
                Upgrade to Premium
              </Button>
              <Button
                onClick={handleSignOut}
                variant="outline"
                className="text-red-600 border-red-600 hover:bg-red-50"
              >
                Log Out
              </Button>
            </div>
          </div>
        </header>

        <main className="flex-1 overflow-y-auto p-8">
          <div className="bg-white rounded-lg shadow-md p-6 max-w-4xl mx-auto">
            {activeTab === "create" && (
              <>
                {currentPlan === "Premium" ? (
                  <PremiumCreateTest
                    onCreateTest={handleCreateTest}
                    userId={userId}
                  />
                ) : (
                  <>
                    <div
                      className="bg-teal-50 border-l-4 border-teal-500 text-teal-700 p-4 mb-6"
                      role="alert"
                    >
                      <div className="flex">
                        <div className="py-1">
                          <AlertCircle className="h-6 w-6 text-teal-500 mr-4" />
                        </div>
                        <div>
                          <p className="font-bold">Available Subjects</p>
                          <p className="text-sm">
                            Currently, only Critical Analysis & Reasoning Skills
                            (CARS) is available for free users. Upgrade to
                            access all subjects and features!
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                      <div>
                        <h3 className="text-lg font-semibold mb-4 text-gray-700">
                          Available Subjects
                        </h3>
                        {renderSubjects(subjects[0])}
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold mb-4 text-gray-700">
                          Premium Subjects
                        </h3>
                        <Card className="bg-gray-50 border border-gray-200">
                          <CardContent className="pt-6">
                            <ul className="space-y-2">
                              {unavailableSubjects.map((subject) => (
                                <li
                                  key={subject.name}
                                  className="flex items-center text-gray-500"
                                >
                                  <Lock className="w-4 h-4 mr-2 text-teal-500" />
                                  {subject.name}
                                </li>
                              ))}
                            </ul>
                          </CardContent>
                        </Card>
                        <Button
                          className="mt-4 w-full bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white transition-colors duration-200"
                          onClick={() => setIsPremiumPopupOpen(true)}
                        >
                          Upgrade to Premium
                        </Button>
                      </div>
                    </div>

                    <hr className="my-6" />

                    <div
                      className={`mb-6 transition-all duration-300 ${
                        isSubjectSelected
                          ? ""
                          : "opacity-50 pointer-events-none"
                      }`}
                    >
                      <div>
                        <h3 className="text-lg font-semibold mb-2">
                          Number of Questions
                        </h3>
                        {renderQuestionCountSelector()}
                      </div>

                      <hr className="my-6" />
                    </div>

                    {!hasAvailableQuestions() && (
                      <div className="mt-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
                        <p className="font-bold">No questions available</p>
                        <p>
                          You have completed all available questions. Please
                          check back later for new content.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {activeTab === "previous" && (
              <div>
                <h3 className="text-xl font-semibold mb-6 text-gray-800">
                  Test History
                </h3>
                {renderPreviousTestsSection()}
              </div>
            )}

            {activeTab === "performance" && (
              <div>
                <h3 className="text-lg font-semibold mb-6 text-gray-700">
                  Performance Overview
                </h3>
                {renderPerformanceSection()}
              </div>
            )}
          </div>
        </main>
      </div>

      {/* Performance Chart Dialog */}
      <Dialog
        open={isPerformanceChartOpen}
        onOpenChange={setIsPerformanceChartOpen}
      >
        <DialogContent className="sm:max-w-3xl bg-white">
          <DialogHeader>
            <DialogTitle className="text-gray-900">
              CARS Performance Over Time
            </DialogTitle>
            <DialogDescription className="text-gray-600">
              Track your CARS test performance and progress over time.
            </DialogDescription>
          </DialogHeader>
          <div className="w-full h-[400px]">
            {" "}
            {/* Increased height for better visibility */}
            {selectedPerformanceSubject ? (
              getChartData(selectedPerformanceSubject).length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={getChartData(selectedPerformanceSubject)}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 60,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                    <XAxis
                      dataKey="date"
                      stroke="#4B5563"
                      angle={-45}
                      textAnchor="end"
                      height={60}
                      tick={{ fontSize: 12 }}
                    />
                    <YAxis
                      domain={[0, 100]}
                      stroke="#4B5563"
                      tick={{ fontSize: 12 }}
                      label={{
                        value: "Score (%)",
                        angle: -90,
                        position: "insideLeft",
                        style: { textAnchor: "middle" },
                      }}
                    />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: "white",
                        border: "1px solid #E5E7EB",
                        borderRadius: "6px",
                        padding: "10px",
                      }}
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          const data = payload[0].payload;
                          return (
                            <div className="bg-white p-4 shadow-lg rounded-lg border border-gray-200">
                              <p className="font-semibold text-gray-900">
                                Test #{data.testNumber}
                              </p>
                              <p className="text-gray-600">Date: {data.date}</p>
                              <p className="text-blue-600 font-bold">
                                Score: {data.score}%
                              </p>
                              <p className="text-gray-600">
                                Questions: {data.questions}
                              </p>
                              {data.topics && (
                                <p className="text-gray-600">
                                  Topics: {data.topics}
                                </p>
                              )}
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="score"
                      stroke="#3b82f6"
                      strokeWidth={3}
                      dot={{ r: 6, fill: "#3b82f6", strokeWidth: 2 }}
                      activeDot={{ r: 8, fill: "#2563eb" }}
                      connectNulls
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <div className="flex flex-col items-center justify-center h-full text-gray-600">
                  <ClipboardList className="w-16 h-16 text-gray-400 mb-4" />
                  <p className="text-lg font-semibold mb-2">
                    No Test Data Available
                  </p>
                  <p className="text-center text-sm">
                    Complete your first CARS test to start tracking your
                    performance.
                  </p>
                  <Button
                    className="mt-4 bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white"
                    onClick={() => {
                      setIsPerformanceChartOpen(false);
                      setActiveTab("create");
                    }}
                  >
                    Create a Test
                  </Button>
                </div>
              )
            ) : (
              <div className="flex items-center justify-center h-full text-gray-600">
                <p>Select a subject to view performance data.</p>
              </div>
            )}
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsPerformanceChartOpen(false)}
            >
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Login Dialog */}
      <Dialog open={isLoginDialogOpen} onOpenChange={setIsLoginDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Log in or Sign up</DialogTitle>
            <DialogDescription>
              Access your UPangea account or create a new one.
            </DialogDescription>
          </DialogHeader>
          <div className="grid w-full grid-cols-2">
            <button
              onClick={() => setActiveTab("login")}
              className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                activeTab === "login"
                  ? "bg-white bg-opacity-20 text-white"
                  : "text-gray-200 hover:bg-white hover:bg-opacity-10"
              }`}
            >
              Login
            </button>
            <button
              onClick={() => setActiveTab("register")}
              className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                activeTab === "register"
                  ? "bg-white bg-opacity-20 text-white"
                  : "text-gray-200 hover:bg-white hover:bg-opacity-10"
              }`}
            >
              Register
            </button>
          </div>
          {activeTab === "login" && (
            <Card>
              <CardHeader>
                <CardTitle>Login</CardTitle>
                <CardDescription>
                  Enter your email and password to access your account.
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-2">
                <div className="space-y-1">
                  <Label htmlFor="email">Email</Label>
                  <Input id="email" type="email" placeholder="m@example.com" />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="password">Password</Label>
                  <Input id="password" type="password" />
                </div>
              </CardContent>
              <CardFooter>
                <Button className="w-full bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white">
                  Log in
                </Button>
              </CardFooter>
            </Card>
          )}
          {activeTab === "register" && (
            <Card>
              <CardHeader>
                <CardTitle>Create an account</CardTitle>
                <CardDescription>
                  Enter your details to create a new account.
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-2">
                <div className="space-y-1">
                  <Label htmlFor="new-email">Email</Label>
                  <Input
                    id="new-email"
                    type="email"
                    placeholder="m@example.com"
                  />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="new-password">Password</Label>
                  <Input id="new-password" type="password" />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="confirm-password">Confirm Password</Label>
                  <Input id="confirm-password" type="password" />
                </div>
              </CardContent>
              <CardFooter>
                <Button className="w-full bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white">
                  Sign Up
                </Button>
              </CardFooter>
            </Card>
          )}
        </DialogContent>
      </Dialog>

      {/* Profile Dialog */}
      <Dialog open={isProfileOpen} onOpenChange={setIsProfileOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Profile</DialogTitle>
            <DialogDescription>
              View and manage your UPangea profile and subscription.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {session ? (
              <>
                <div className="flex items-center gap-4">
                  <div className="h-16 w-16 bg-gray-200 rounded-full flex items-center justify-center">
                    <User className="w-8 h-8 text-gray-500" />
                  </div>
                  <div>
                    <h3 className="font-medium">{session.user.email}</h3>
                    <p className="text-sm text-gray-500">
                      {session.user.email}
                    </p>
                  </div>
                </div>
                <hr />
                <div>
                  <h4 className="font-medium mb-2">Current Plan</h4>
                  <p className="text-sm text-gray-500">Free</p>
                  <Button className="mt-2 bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white">
                    Upgrade to Premium
                  </Button>
                </div>
                <hr />
                <div>
                  <h4 className="font-medium mb-2">Account Settings</h4>
                  <Button variant="outline" className="w-full justify-start">
                    <Settings className="mr-2 h-4 w-4" />
                    Edit Profile
                  </Button>
                  <Button
                    variant="outline"
                    className="w-full justify-start mt-2"
                  >
                    <CreditCard className="mr-2 h-4 w-4" />
                    Manage Subscription
                  </Button>
                </div>
              </>
            ) : (
              <p>Please log in to view your profile.</p>
            )}
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsProfileOpen(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Error Modal */}
      <Dialog open={isErrorModalOpen} onOpenChange={setIsErrorModalOpen}>
        <DialogContent className="bg-white">
          <DialogHeader>
            <DialogTitle>Error</DialogTitle>
          </DialogHeader>
          <p>{errorMessage}</p>
          <DialogFooter>
            <Button onClick={() => setIsErrorModalOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Initial Reset Confirmation Dialog */}
      <Dialog open={isResetConfirmOpen} onOpenChange={setIsResetConfirmOpen}>
        <DialogContent className="bg-white">
          <DialogHeader>
            <DialogTitle>Confirm Reset</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Are you sure you want to reset all your progress? This action cannot
            be undone.
          </DialogDescription>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsResetConfirmOpen(false)}
            >
              Cancel
            </Button>
            <Button onClick={handleResetConfirm}>Confirm</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Type Confirmation Dialog */}
      <Dialog
        open={isResetTypeConfirmOpen}
        onOpenChange={setIsResetTypeConfirmOpen}
      >
        <DialogContent className="bg-white">
          <DialogHeader>
            <DialogTitle>Final Confirmation</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            To reset all progress, please type &quot;RESET&quot; in the box
            below:
          </DialogDescription>
          <Input
            value={resetConfirmText}
            onChange={(e) => setResetConfirmText(e.target.value)}
            placeholder="Type RESET here"
          />
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsResetTypeConfirmOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleResetTypeConfirm}
              disabled={resetConfirmText.toUpperCase() !== "RESET"}
            >
              Reset All Progress
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* <Elements stripe={stripePromise}> */}
      <BetaSignupPopup
        isOpen={isPremiumPopupOpen}
        setIsOpen={setIsPremiumPopupOpen}
        session={session}
      />
      {/* </Elements> */}
    </div>
  );
}
