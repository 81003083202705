// src/components/ui/table.js
import React from "react";
import { cn } from "../../lib/utils";

export function Table({ children, ...props }) {
  return (
    <table
      className="min-w-full table-auto divide-y divide-gray-200"
      {...props}
    >
      {children}
    </table>
  );
}

export function TableHeader({ children, ...props }) {
  return (
    <thead className="bg-gray-50" {...props}>
      {children}
    </thead>
  );
}

export function TableBody({ children, ...props }) {
  return (
    <tbody className="bg-white divide-y divide-gray-200" {...props}>
      {children}
    </tbody>
  );
}

export function TableRow({ children, className, ...props }) {
  return (
    <tr className={cn("hover:bg-gray-100", className)} {...props}>
      {children}
    </tr>
  );
}

export function TableHead({ children, ...props }) {
  return (
    <th
      className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
      {...props}
    >
      {children}
    </th>
  );
}

export function TableCell({ children, ...props }) {
  return (
    <td
      className="px-6 py-3 whitespace-nowrap text-sm text-gray-900"
      {...props}
    >
      {children}
    </td>
  );
}
