import React from "react";
import ReactDOM from "react-dom/client";
import { supabase } from "./supabaseClient"; // Adjust the import based on your project structure
import "./index.css"; // Make sure this file exists in your src folder
import App from "./App";

export const SessionContext = React.createContext(null);

function Main() {
  const [session, setSession] = React.useState(null);

  React.useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN") {
        setSession(session); // Set the user when signed in
      } else if (event === "SIGNED_OUT") {
        setSession(null); // Reset user when signed out
      } else if (session) {
        setSession(session); // Unknown auth event but session exists
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <SessionContext.Provider value={session}>
      <App />
    </SessionContext.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
);
