import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { Button } from "../components/ui/button";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";
import { Label } from "../components/ui/label";
import { Textarea } from "../components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { ScrollArea } from "../components/ui/scroll-area";
import { Switch } from "../components/ui/switch";
import {
  Clock,
  Flag,
  ChevronLeft,
  ChevronRight,
  Menu,
  MessageSquare,
  Highlighter,
  Scissors,
  Play,
  Eraser,
  CheckCircle,
  XCircle,
  AlertCircle,
} from "lucide-react";
import { useHotkeys } from "react-hotkeys-hook";
import { SessionContext } from "../index";

export default function TestInterface({
  isReviewMode: propIsReviewMode = false,
}) {
  const { testId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const imagePrefixUrl =
    "https://lzgkrezflvwezmjakdyv.supabase.co/storage/v1/object/public/upangea_images/";

  // State variables
  const [passages, setPassages] = useState([]);
  const [currentPassageIndex, setCurrentPassageIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showSolution, setShowSolution] = useState(false);
  const [markups, setMarkups] = useState({});
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [flaggedQuestions, setFlaggedQuestions] = useState([]);
  const [showNavigation, setShowNavigation] = useState(false);
  const [showEndTestConfirmation, setShowEndTestConfirmation] = useState(false);
  const [showTestSummary, setShowTestSummary] = useState(false);
  const [confidenceRatings, setConfidenceRatings] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [lockedQuestions, setLockedQuestions] = useState({});
  const [isCorrectAnswers, setIsCorrectAnswers] = useState({});

  /**  for images and tables
  const imageData = useRef(
    new Map([
      ["question", new Map()],
      ["choice", new Map()],
      ["passage", new Map()],
      ["explanation", new Map()],
    ]),
  );
  const chemicalEquation = useRef([]);
  const isLastLineChemicalEquation = useRef(false);
  */

  const currentPassage = passages[currentPassageIndex] || null;
  const currentQuestion =
    currentPassage?.questions[currentQuestionIndex] || null;
  const contentRef = useRef(null);

  // Determine if we're in review mode based on both prop and URL
  const isReviewMode =
    propIsReviewMode || location.pathname.includes("/review");

  // Helper Functions
  const getTotalQuestions = useCallback(() => {
    return passages.reduce(
      (total, passage) => total + (passage.questions?.length || 0),
      0,
    );
  }, [passages]);

  const getCurrentQuestionNumber = useCallback(() => {
    let questionNumber = currentQuestionIndex + 1;
    for (let i = 0; i < currentPassageIndex; i++) {
      questionNumber += passages[i].questions?.length || 0;
    }
    return questionNumber;
  }, [currentQuestionIndex, currentPassageIndex, passages]);

  const getQuestionRangeForPassage = useCallback(
    (passageIndex) => {
      let startingQuestion = 1;
      for (let i = 0; i < passageIndex; i++) {
        startingQuestion += passages[i].questions.length;
      }
      const endingQuestion =
        startingQuestion + (passages[passageIndex]?.questions?.length || 0) - 1;
      return { startingQuestion, endingQuestion };
    },
    [passages],
  );

  const isLastQuestion = useCallback(() => {
    return (
      currentPassageIndex === passages.length - 1 &&
      currentQuestionIndex === (currentPassage?.questions.length || 0) - 1
    );
  }, [currentPassageIndex, currentQuestionIndex, passages, currentPassage]);

  const getActualQuestionNumber = useCallback(
    (passageIndex, questionIndex) => {
      let questionNumber = 1;
      for (let i = 0; i < passageIndex; i++) {
        questionNumber += passages[i].questions.length;
      }
      return questionNumber + questionIndex;
    },
    [passages],
  );

  // Helper function to get the selected range
  const getSelectedRange = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return null;

    const range = selection.getRangeAt(0);
    const startContainer = range.startContainer;
    const endContainer = range.endContainer;

    if (
      !(startContainer.parentElement instanceof HTMLElement) ||
      !(endContainer.parentElement instanceof HTMLElement)
    ) {
      return null;
    }

    const startElement = startContainer.parentElement.closest("[data-offset]");
    const endElement = endContainer.parentElement.closest("[data-offset]");

    if (!startElement || !endElement) return null;

    const elementId = startElement.getAttribute("data-element-id") || "";
    const startOffset =
      parseInt(startElement.getAttribute("data-offset") || "0", 10) +
      range.startOffset;
    const endOffset =
      parseInt(endElement.getAttribute("data-offset") || "0", 10) +
      range.endOffset;

    return {
      range: { start: startOffset, end: endOffset },
      elementId,
    };
  };

  // Function to handle applying/removing markups
  const handleMarkup = useCallback((type) => {
    const selectionInfo = getSelectedRange();
    if (selectionInfo) {
      const { range, elementId } = selectionInfo;
      setMarkups((prev) => {
        const existingMarkups = prev[elementId] || [];
        const updatedMarkups = existingMarkups.filter(
          (markup) =>
            !(
              markup.range.start === range.start &&
              markup.range.end === range.end &&
              markup.type === type
            ),
        );

        if (updatedMarkups.length === existingMarkups.length) {
          // If no existing markup of this type, add it
          updatedMarkups.push({ type, range });
        }

        return {
          ...prev,
          [elementId]: updatedMarkups,
        };
      });
    }
  }, []);

  // Hotkeys for Markup
  const handleHighlight = useCallback(() => {
    handleMarkup("highlight");
  }, [handleMarkup]);

  const handleStrikethrough = useCallback(() => {
    handleMarkup("strikethrough");
  }, [handleMarkup]);

  useHotkeys("alt+h", handleHighlight, { preventDefault: true });
  useHotkeys("alt+s", handleStrikethrough, { preventDefault: true });

  // Fetch Test Data
  const fetchTestData = async () => {
    if (!testId || !session) return;

    setIsLoading(true);

    try {
      const supabaseUserId = session.user.id;

      // Fetch the test data
      const { data: testData, error: testError } = await supabase
        .from("tests_new")
        .select("*")
        .eq("id", testId)
        .eq("user_id", supabaseUserId)
        .single();

      if (testError) throw testError;

      // Now fetch the passages using the passage_ids
      const passageIds = testData.passage_ids;

      const { data: passagesData, error: passagesError } = await supabase
        .from("passages")
        .select(
          `
          *,
          questions (
            id,
            text,
            correct_answer,
            explanations,
            choices (*)
          )
        `,
        )
        .in("id", passageIds);

      if (passagesError) throw passagesError;

      setPassages(passagesData);

      /** for non-CARS image and table
      const { data: passageImagesData, error: passageImagesError } =
        await supabase
          .from("media_metadata")
          .select("*")
          .in("related_id", passageIds)
          .eq("media_type", "passage")
          .order("related_id", { ascending: true })
          .order("file_name", { ascending: true });
      if (passageImagesError) throw passageImagesError;

      for (let i = 0; i < passagesData.length; i++) {
        const questionIds = passagesData[i].questions.map((q) => q.id);
        const { data: questionImagesData, error: questionImagesError } =
          await supabase
            .from("media_metadata")
            .select("*")
            .in("related_id", questionIds)
            .eq("media_type", "question")
            .order("related_id", { ascending: true })
            .order("file_name", { ascending: true });
        if (questionImagesError) throw questionImagesError;

        const matchedPassages = passageImagesData.filter(
          (item) => item.related_id == passagesData[i].id,
        );
        if (matchedPassages.length > 0) {
          if (imageData.current.get("passage").has(i)) {
            for (const matchedPassage of matchedPassages) {
              if (
                !imageData.current
                  .get("passage")
                  .get(i)
                  .some((item) => item.id === matchedPassage.id)
              ) {
                imageData.current.get("passage").get(i).push(matchedPassage);
              }
            }
          } else {
            imageData.current.get("passage").set(i, matchedPassages);
          }
        }
        if (questionImagesData.length > 0) {
          for (let j = 0; j < questionImagesData.length; j++) {
            const relatedId = questionImagesData[j].related_id;
            if (
              questionImagesData[j].folder_name === "answer_choice_images" ||
              questionImagesData[j].folder_name === "answer_choice_tables"
            ) {
              if (imageData.current.get("choice").has(i)) {
                if (
                  imageData.current.get("choice").get(i).has(relatedId) &&
                  !imageData.current
                    .get("choice")
                    .get(i)
                    .get(relatedId)
                    .some((item) => item.id === questionImagesData[j].id)
                ) {
                  imageData.current
                    .get("choice")
                    .get(i)
                    .get(relatedId)
                    .push(questionImagesData[j]);
                } else {
                  imageData.current
                    .get("choice")
                    .get(i)
                    .set(relatedId, [questionImagesData[j]]);
                }
              } else {
                imageData.current
                  .get("choice")
                  .set(i, new Map([[relatedId, [questionImagesData[j]]]]));
              }
            } else if (
              questionImagesData[j].folder_name === "question_images" ||
              questionImagesData[j].folder_name === "question_tables"
            ) {
              console.log("++++we did question!");
              if (imageData.current.get("question").has(i)) {
                if (
                  imageData.current.get("question").get(i).has(relatedId) &&
                  !imageData.current
                    .get("question")
                    .get(i)
                    .get(relatedId)
                    .some((item) => item.id === questionImagesData[j].id)
                ) {
                  imageData.current
                    .get("question")
                    .get(i)
                    .get(relatedId)
                    .push(questionImagesData[j]);
                } else {
                  imageData.current
                    .get("question")
                    .get(i)
                    .set(relatedId, [questionImagesData[j]]);
                }
              } else {
                imageData.current
                  .get("question")
                  .set(i, new Map([[relatedId, [questionImagesData[j]]]]));
              }
            } else if (
              questionImagesData[j].folder_name ===
                "question_explanation_images" ||
              questionImagesData[j].folder_name ===
                "question_explanation_tables"
            ) {
              if (imageData.current.get("explanation").has(i)) {
                if (
                  imageData.current.get("explanation").get(i).has(relatedId) &&
                  !imageData.current
                    .get("explanation")
                    .get(i)
                    .get(relatedId)
                    .some((item) => item.id === questionImagesData[j].id)
                ) {
                  imageData.current
                    .get("explanation")
                    .get(i)
                    .get(relatedId)
                    .push(questionIds[j]);
                } else {
                  imageData.current
                    .get("explanation")
                    .get(i)
                    .set(relatedId, [questionImagesData[j]]);
                }
              } else {
                imageData.current
                  .get("explanation")
                  .set(i, new Map([[relatedId, [questionImagesData[j]]]]));
              }
            }
          }
        }
      }
         */

      // If in review mode, fetch selected answers
      if (isReviewMode) {
        const { data: selectedAnswersData, error: selectedAnswersError } =
          await supabase
            .from("test_answers_cars")
            .select("*")
            .eq("test_id", testId)
            .order("question_id", { ascending: true });

        if (selectedAnswersError) throw selectedAnswersError;

        const selectedAnswersObj = {};
        const confidenceRatingsObj = {};
        const flaggedQuestionsArr = [];
        const markupsObj = {};
        const isCorrectObj = {};

        // Initialize all questions as undefined first
        passagesData.forEach((passage) => {
          passage.questions.forEach((question) => {
            selectedAnswersObj[question.id] = undefined;
            confidenceRatingsObj[question.id] = null;
            isCorrectObj[question.id] = false;
          });
        });

        // Then update only the answered questions
        selectedAnswersData.forEach((answer) => {
          if (!answer) return;

          // Store selected answers (only if there was an answer)
          if (answer.selected_answer) {
            selectedAnswersObj[answer.question_id] = labelToIndex(
              answer.selected_answer,
            );
          }

          // Store confidence ratings
          confidenceRatingsObj[answer.question_id] =
            answer.confidence_rating || null;

          // Store flagged questions
          if (answer.flagged) {
            flaggedQuestionsArr.push(answer.question_id);
          }

          // Store all markups, ensuring they're valid JSON
          try {
            if (answer.question_markups) {
              markupsObj[`question-${answer.question_id}`] = JSON.parse(
                answer.question_markups,
              );
            }

            if (answer.choice_markups) {
              const choiceMarkups = JSON.parse(answer.choice_markups);
              choiceMarkups.forEach((markup, index) => {
                markupsObj[`choice-${answer.question_id}-${index}`] = markup;
              });
            }

            if (answer.passage_markups) {
              markupsObj[`passage-${answer.passage_id}`] = JSON.parse(
                answer.passage_markups,
              );
            }
          } catch (e) {
            console.error("Error parsing markups:", e);
          }

          // Store correct/incorrect status
          isCorrectObj[answer.question_id] = answer.is_correct;
        });

        console.log("()()()()() we got here:", confidenceRatingsObj);
        setSelectedAnswers(selectedAnswersObj);
        setConfidenceRatings(confidenceRatingsObj);
        setFlaggedQuestions(flaggedQuestionsArr);
        setMarkups(markupsObj);
        setIsCorrectAnswers(isCorrectObj);

        // Lock all questions in review mode
        const lockedQuestionsObj = {};
        selectedAnswersData.forEach((answer) => {
          if (answer && answer.question_id) {
            lockedQuestionsObj[answer.question_id] = true;
          }
        });
        setLockedQuestions(lockedQuestionsObj);

        // Always show solution in review mode
        setShowSolution(true);
      } else {
        // Initialize test progress
        const { data: progressData, error: progressError } = await supabase
          .from("test_progress")
          .insert({
            user_id: supabaseUserId,
            passage_index: 0,
            question_index: 0,
            elapsed_time: 0,
          })
          .select()
          .single();

        if (progressError) throw progressError;
      }

      // Set initial passage and question indices
      setCurrentPassageIndex(0);
      setCurrentQuestionIndex(0);
    } catch (error) {
      console.error("Error fetching test data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTestData();
  }, [testId, isReviewMode]);

  useEffect(() => {
    if (isReviewMode) {
      setShowSolution(true);
    }
  }, [isReviewMode]);

  // **New useEffect to reset showSolution when question changes**
  useEffect(() => {
    if (!isReviewMode) {
      setShowSolution(false);
    }
  }, [currentPassageIndex, currentQuestionIndex, isReviewMode]);

  // Add this helper function near the top of the file, after the imports
  const indexToLabel = (index) => {
    return String.fromCharCode(65 + index); // Converts 0 to 'A', 1 to 'B', etc.
  };

  const labelToIndex = (label) => {
    return label.charCodeAt(0) - 65; // Converts 'A' to 0, 'B' to 1, etc.
  };

  // Update the handleAnswerSelect function
  const handleAnswerSelect = (questionId, choiceIndex) => {
    if (isReviewMode || lockedQuestions[questionId]) return;

    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: choiceIndex,
    }));
  };

  // Timer Effect
  useEffect(() => {
    let interval = null;
    if (!isReviewMode && isTimerRunning) {
      interval = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTimerRunning, isReviewMode]);

  // Start timer when test is loaded
  useEffect(() => {
    if (!isReviewMode && passages.length > 0) {
      setIsTimerRunning(true);
    }
  }, [passages, isReviewMode]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const toggleTimer = () => {
    setIsTimerRunning((prev) => !prev);
  };

  // Handle Show Solution
  const handleShowSolution = () => {
    if (!currentQuestion) return;

    if (!showSolution) {
      if (selectedAnswers[currentQuestion.id] === undefined) {
        return;
      }
      setLockedQuestions((prev) => ({
        ...prev,
        [currentQuestion.id]: true,
      }));
    }

    console.log("Current question:", currentQuestion);
    console.log("Correct answer:", currentQuestion.correct_answer);
    console.log("Selected answer:", selectedAnswers[currentQuestion.id]);

    setShowSolution((prev) => !prev);
  };

  // Handle Navigation
  const handleNextQuestion = () => {
    if (
      currentPassage &&
      currentQuestionIndex < currentPassage.questions.length - 1
    ) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else if (currentPassageIndex < passages.length - 1) {
      setCurrentPassageIndex((prev) => prev + 1);
      setCurrentQuestionIndex(0);
    }
    setShowSolution(false);
    console.log(
      "Moving to next question, new ID:",
      passages[currentPassageIndex]?.questions[currentQuestionIndex + 1]?.id,
    );
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
    } else if (currentPassageIndex > 0) {
      setCurrentPassageIndex((prev) => prev - 1);
      setCurrentQuestionIndex(
        passages[currentPassageIndex - 1].questions.length - 1,
      );
    }
    setShowSolution(false);
    console.log(
      "Moving to previous question, new ID:",
      passages[currentPassageIndex]?.questions[currentQuestionIndex - 1]?.id,
      currentQuestionIndex,
    );
  };

  const handlePreviousPassage = () => {
    if (currentPassageIndex > 0) {
      setCurrentPassageIndex((prev) => prev - 1);
      setCurrentQuestionIndex(0);
      setShowSolution(false);
    }
  };

  const handleNextPassage = () => {
    if (currentPassageIndex < passages.length - 1) {
      setCurrentPassageIndex((prev) => prev + 1);
      setCurrentQuestionIndex(0);
      setShowSolution(false);
    }
  };

  // Handle Highlight and Strikethrough via Hotkeys
  // These functions are now properly defined with handleMarkup
  // (Already defined above)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (passages.length === 0) {
    console.log("No passages available. Current state:", {
      passages,
      currentPassageIndex,
      currentQuestionIndex,
    });
    return (
      <div>
        No passages available in the database. Check console for more
        information.
      </div>
    );
  }

  if (
    !passages[currentPassageIndex] ||
    !passages[currentPassageIndex].questions[currentQuestionIndex]
  ) {
    console.log("No current passage or question. Current state:", {
      passages,
      currentPassageIndex,
      currentQuestionIndex,
      currentPassage: passages[currentPassageIndex],
      currentQuestion:
        passages[currentPassageIndex]?.questions?.[currentQuestionIndex],
    });
    return (
      <div>
        No passages or questions available. Please check the console for more
        information.
      </div>
    );
  }

  if (!currentQuestion) {
    return (
      <div>
        No question data available. Please check the console for more
        information.
      </div>
    );
  }

  // Handle Markups
  const handleClearMarkups = () => setMarkups({});

  // Updated parseChoiceText to extract label and text
  const parseChoiceText = (text) => {
    const regex = /^([A-Z])\.\s*(.*?)(?:\s*\((\d+)%\))?$/;
    const match = text.match(regex);
    if (match) {
      return {
        label: match[1],
        text: match[2].trim(),
        percentage: match[3] ? match[3] : null,
      };
    }
    return {
      label: "",
      text: text,
      percentage: null,
    };
  };

  /** this is for non-CARS passages
  const isEquationLine = (line) => {
    const isShortLine = line.length < 9;
    const containsEquationSymbols = /[+\-=⇆→←()]/.test(line);
    const lacksSentencePunctuation = !/[:.!?]$/.test(line);
    const containsPunctuation = /[:.!?]$/.test(line);
    const noWay = /^(Reaction|Equation) \d+/.test(line);
    if (containsPunctuation) {
      if (
        isLastLineChemicalEquation.current &&
        chemicalEquation.current.length > 0
      ) {
        return { isEquation: false, return: true };
      } else {
        return { isEquation: false, return: false };
      }
    }
    if (
      noWay &&
      isLastLineChemicalEquation.current &&
      chemicalEquation.current.length > 0
    ) {
      return { isEquation: false, return: true, include: false };
    } else if (lacksSentencePunctuation && isShortLine) {
      return { isEquation: true, return: false };
    } else if (isLastLineChemicalEquation.current && lacksSentencePunctuation) {
      return { isEquation: true, return: false };
    } else if (containsEquationSymbols && lacksSentencePunctuation) {
      return { isEquation: true, return: false };
    } else if (
      isLastLineChemicalEquation.current &&
      chemicalEquation.current.length > 0
    ) {
      return { isEquation: false, return: true };
    } else {
      return { isEquation: false, return: false };
    }
  };
  
  const renderImage = (type, index = 0) => {
    let result = [];
    let renderImages = false;
    let renderImageType = "";
    if (imageData.current.size > 0) {
      switch (type) {
        case "passage":
          if (
            imageData.current.get("passage").has(currentPassageIndex) &&
            imageData.current.get("passage").get(currentPassageIndex).length > 0
          ) {
            renderImages = true;
            renderImageType = "passage";
          }
          break;
        case "question":
          if (
            imageData.current.get("question").has(currentPassageIndex) &&
            imageData.current
              .get("question")
              .get(currentPassageIndex)
              .has(currentQuestion.id)
          ) {
            renderImages = true;
            renderImageType = "question";
          }
          break;
        case "choice":
          if (
            imageData.current.get("choice").has(currentPassageIndex) &&
            imageData.current
              .get("choice")
              .get(currentPassageIndex)
              .has(currentQuestion.id)
          ) {
            renderImages = true;
            renderImageType = "choice";
          }
          break;
        case "explanation":
          if (
            imageData.current.get("explanation").has(currentPassageIndex) &&
            imageData.current
              .get("explanation")
              .get(currentPassageIndex)
              .has(currentQuestion.id)
          ) {
            renderImages = true;
            renderImageType = "explanation";
          }
          break;
      }
    }
    if (renderImages) {
      if (renderImageType === "passage") {
        for (const passageImage of imageData.current
          .get(renderImageType)
          .get(currentPassageIndex)) {
          if (passageImage.folder_name === "passage_tables") {
            continue;
          }
          const imageUrl = `${imagePrefixUrl}${passageImage.file_path}`;
          result.push(
            <img
              key={`image-${passageImage.id}`}
              src={imageUrl}
              alt={"passageImage.alt_text"}
              className="object-contain py-2"
            />,
          );
        }
      } else if (renderImageType === "question") {
        for (const questionImage of imageData.current
          .get(renderImageType)
          .get(currentPassageIndex)
          .get(currentQuestion.id)) {
          const imageUrl = `${imagePrefixUrl}${questionImage.file_path}`;
          result.push(
            <img
              key={`image-${questionImage.id}`}
              src={imageUrl}
              alt={"questionImage.alt_text"}
              className="object-contain"
            />,
          );
        }
      } else if (renderImageType === "choice") {
        const choiceImage = imageData.current
          .get(renderImageType)
          .get(currentPassageIndex)
          .get(currentQuestion.id)[index];
        const imageUrl = `${imagePrefixUrl}${choiceImage?.file_path}`;
        result.push(
          <img
            key={`image-${choiceImage?.id}`}
            src={imageUrl}
            alt={"choiceImage.alt_text"}
            className="object-contain"
          />,
        );
      } else if (renderImageType === "explanation") {
        for (const explanationImage of imageData.current
          .get(renderImageType)
          .get(currentPassageIndex)
          .get(currentQuestion.id)) {
          const imageUrl = `${imagePrefixUrl}${explanationImage.file_path}`;
          result.push(
            <img
              key={`image-${explanationImage.id}`}
              src={imageUrl}
              alt={"explanationImage.alt_text"}
              className="object-contain"
            />,
          );
        }
      }
    }

    return result;
  };
  */

  // Render with Markup Function (unchanged)
  const renderWithMarkup = (text, elementId, type, baseOffset = 0) => {
    if (!text) return null;
    let result = [];

    /** non-CARS
    const isEquation = isEquationLine(text);
    if (isEquation.return) {
      if (chemicalEquation.current.length > 0) {
        isLastLineChemicalEquation.current = false;
        result.push(<span>{chemicalEquation.current.join("")}</span>);
        chemicalEquation.current = []; // could be wrong?
        if (!isEquation.include) result.push(<span>{"\n"}</span>);
      }
    } else if (isEquation.isEquation) {
      isLastLineChemicalEquation.current = true;
      chemicalEquation.current.push(text);
      return null;
    }

    if (type === "passage" && text.includes("[[TABLE_PLACEHOLDER]]")) {
      let passageTable = imageData.current
        .get("passage")
        .get(currentPassageIndex);
      if (passageTable && passageTable.length > 0) {
        passageTable = passageTable.filter(
          (table) => table.folder_name === "passage_tables",
        );
        return (
          <div className="py-2">
            {passageTable.map((table) => (
              <img
                key={`table-${table.id}`}
                src={`${imagePrefixUrl}${table.file_path}`}
                alt={table.alt_text}
                className="object-contain"
              />
            ))}
          </div>
        );
      }
    }
    */

    const elementMarkups = markups[elementId] || [];
    const sortedMarkups = elementMarkups
      .filter(
        (markup) =>
          markup.range.start >= baseOffset &&
          markup.range.end <= baseOffset + text.length,
      )
      .sort((a, b) => a.range.start - b.range.start);

    let lastIndex = baseOffset;

    for (let i = 0; i < text.length; i++) {
      const currentOffset = baseOffset + i;
      const char = text[i];

      // Handle newline characters
      if (char === "\n") {
        result.push(<br key={`br-${currentOffset}`} />);
        continue;
      }

      // Optionally handle carriage returns if present
      if (char === "\r") {
        // Skip carriage return if followed by newline
        if (text[i + 1] === "\n") {
          continue;
        }
        result.push(<br key={`br-${currentOffset}`} />);
        continue;
      }

      const activeMarkups = sortedMarkups.filter(
        (markup) =>
          markup.range.start <= currentOffset &&
          markup.range.end > currentOffset,
      );

      let content = char;
      if (activeMarkups.some((m) => m.type === "highlight")) {
        content = <mark key={`highlight-${currentOffset}`}>{char}</mark>;
      }
      if (activeMarkups.some((m) => m.type === "strikethrough")) {
        content = <s key={`strikethrough-${currentOffset}`}>{char}</s>;
      }

      result.push(
        <span
          key={`text-${currentOffset}`}
          data-offset={currentOffset}
          data-element-id={elementId}
        >
          {content}
        </span>,
      );

      lastIndex = currentOffset + 1;
    }
    /** non-CARS
    if (type === "passage" && /^(Figure|Table) \d+\s{2}/.test(text)) {
      const regex = /^(Figure|Table) (\d+)\s{2}/;
      const match = text.match(regex);
      const passageTable = imageData.current
        .get("passage")
        .get(currentPassageIndex);
      if (passageTable && passageTable.length > 0) {
        const newPassageTable = passageTable.filter(
          (table) => table.folder_name === "passage_images",
        );
        const parsedIndex = parseInt(match[2], 10) - 1;
        const matched = newPassageTable[parsedIndex];
        if (newPassageTable && matched) {
          result.push(
            <img
              key={`table-${matched.id}`}
              src={`${imagePrefixUrl}${matched.file_path}`}
              alt={"inserted.alt_text"}
              className="object-contain py-2"
            />,
          );
        }
      }
    }
      */

    return result;
  };

  // Handle Flagging Questions
  const handleFlagForReview = () => {
    if (!currentQuestion) return;
    setFlaggedQuestions((prev) =>
      prev.includes(currentQuestion.id)
        ? prev.filter((id) => id !== currentQuestion.id)
        : [...prev, currentQuestion.id],
    );
  };

  // Handle Confidence Rating
  const handleConfidenceRating = (rating) => {
    console.log("()()()()()Current confidenceRating :", confidenceRatings);
    console.log("()()()()()Current question:", currentQuestion.id, rating);
    if (!currentQuestion) return;
    setConfidenceRatings((prev) => ({
      ...prev,
      [currentQuestion.id]: rating,
    }));
    console.log("()()()()()updated confidenceRating :", confidenceRatings);
  };

  // Handle Ending Test
  const handleEndTest = () => {
    setShowEndTestConfirmation(true);
  };

  const confirmEndTest = () => {
    setShowEndTestConfirmation(false);
    setShowTestSummary(true);
  };

  const cancelEndTest = () => {
    setShowEndTestConfirmation(false);
  };

  // Finalize Test Submission
  const finalizeTest = async () => {
    setShowTestSummary(false);

    if (!session || !testId) {
      console.error("User or testId is missing");
      return;
    }

    if (!passages || passages.length === 0) {
      console.error("No passages found");
      return;
    }

    try {
      const supabaseUserId = session.user.id;

      // Calculate the score
      const totalQuestions = getTotalQuestions();
      const correctAnswers = Object.entries(selectedAnswers).reduce(
        (sum, [questionId, answerIndex]) => {
          if (answerIndex === undefined || answerIndex === null) return sum;

          const question = passages
            .flatMap((p) => p.questions)
            .find((q) => q.id === parseInt(questionId));

          if (!question) return sum;

          return (
            sum +
            (indexToLabel(answerIndex) === question.correct_answer ? 1 : 0)
          );
        },
        0,
      );

      const score = Math.round((correctAnswers / totalQuestions) * 100);

      console.log("Calculated score:", score);

      // Update the test record
      const { error: testUpdateError } = await supabase
        .from("tests_new")
        .update({
          score: score,
        })
        .eq("id", testId);

      if (testUpdateError) {
        console.error("Error updating test record:", testUpdateError);
        throw testUpdateError;
      }

      // Prepare data for test_answers with validation
      const testAnswersData = [];

      passages.forEach((passage) => {
        if (!passage || !passage.id) return;

        const passageMarkups = markups[`passage-${passage.id}`] || [];

        passage.questions.forEach((question) => {
          if (!question || !question.id) return;

          const questionId = question.id;
          const selectedAnswer =
            selectedAnswers[questionId] !== undefined &&
            selectedAnswers[questionId] !== null
              ? indexToLabel(selectedAnswers[questionId])
              : null;

          const isCorrect = selectedAnswer === question.correct_answer;
          const confidenceRating = confidenceRatings[questionId] || null;
          console.log("()()()()()editing to :", confidenceRating);
          const flagged = flaggedQuestions.includes(questionId);

          let questionMarkups = [];
          let choiceMarkups = [];

          try {
            questionMarkups = markups[`question-${questionId}`] || [];
            choiceMarkups = question.choices.map(
              (_, index) => markups[`choice-${questionId}-${index}`] || [],
            );
          } catch (e) {
            console.error(
              "Error processing markups for question:",
              questionId,
              e,
            );
          }

          const answerData = {
            test_id: testId,
            question_id: questionId,
            passage_id: passage.id,
            selected_answer: selectedAnswer,
            is_correct: isCorrect,
            confidence_rating: confidenceRating,
            flagged: flagged,
            question_markups: JSON.stringify(questionMarkups),
            choice_markups: JSON.stringify(choiceMarkups),
            passage_markups: JSON.stringify(passageMarkups),
          };

          testAnswersData.push(answerData);
        });
      });

      if (testAnswersData.length === 0) {
        throw new Error("No valid test answers to save");
      }

      // Insert test answers into the database
      const { error: insertTestAnswersError } = await supabase
        .from("test_answers_cars")
        .insert(testAnswersData);

      if (insertTestAnswersError) {
        console.error("Error inserting test answers:", insertTestAnswersError);
        throw insertTestAnswersError;
      }

      // Navigate back to the dashboard
      navigate("/dashboard");
    } catch (error) {
      console.error("Error finalizing test:", error);
      alert(
        `An error occurred while saving your test results: ${error.message}`,
      );
    }
  };

  const getTotalIncompleteQuestions = () => {
    return passages.reduce(
      (total, passage) =>
        total +
        passage.questions.filter((q) => selectedAnswers[q.id] === undefined)
          .length,
      0,
    );
  };

  // Handle Feedback Submission
  const handleSubmitFeedback = async () => {
    if (feedbackText.trim() === "") return;

    try {
      const supabaseUserId = session.user.id;

      // Insert feedback into the 'feedback' table
      const { error: insertError } = await supabase
        .from("feedback_new")
        .insert([
          {
            user_id: supabaseUserId,
            test_id: testId, // Optional: Include if feedback is tied to a specific test
            feedback_text: feedbackText,
          },
        ]);

      if (insertError) throw insertError;

      // Success feedback to user
      alert("Feedback submitted. Thank you!");
      setFeedbackText("");
      setShowFeedbackDialog(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      alert(
        `An error occurred while submitting your feedback: ${error.message}`,
      );
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100 font-[Arial]">
      {/* Header */}
      <header className="bg-[#0066cc] text-white p-2 flex justify-between items-center">
        <h1 className="text-lg font-bold">
          {isReviewMode ? "Test Review" : "Medical College Admission Test"} -{" "}
          {session?.user?.name || "Guest"}
        </h1>
        <div className="flex items-center space-x-4">
          <Button
            variant="ghost"
            size="sm"
            onClick={toggleTimer}
            className="text-white hover:bg-white hover:text-[#0066cc] transition-colors duration-200 flex items-center space-x-2"
          >
            <Clock className="w-4 h-4 mr-1" />
            <span>{formatTime(elapsedTime)}</span>
            {!isTimerRunning && <Play className="w-4 h-4 ml-1" />}
          </Button>
          <span className="text-sm">
            Question {getCurrentQuestionNumber()} of {getTotalQuestions()}
          </span>
        </div>
      </header>

      {/* Main Content */}
      {isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
            <p className="mt-4 text-gray-600">Loading test data...</p>
          </div>
        </div>
      ) : (
        <>
          {/* Toolbar */}
          <div className="bg-[#4a90e2] text-white p-2 flex justify-between items-center">
            <div className="flex space-x-4">
              <Button
                variant="ghost"
                size="sm"
                onClick={handleHighlight}
                className="text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200"
              >
                <Highlighter className="w-4 h-4 mr-1" />
                Highlight
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleStrikethrough}
                className="text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200"
              >
                <Scissors className="w-4 h-4 mr-1" />
                Strikethrough
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleClearMarkups}
                className="text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200"
              >
                <Eraser className="w-4 h-4 mr-1" />
                Clear
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setShowFeedbackDialog(true)}
                className="text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200"
              >
                <MessageSquare className="w-4 h-4 mr-1" />
                Feedback
              </Button>
            </div>
            <Button
              variant="ghost"
              size="sm"
              onClick={handleFlagForReview}
              className={`text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200 ${
                flaggedQuestions.includes(currentQuestion?.id)
                  ? "bg-white !text-[#4a90e2]"
                  : ""
              }`}
            >
              <Flag
                className={`w-4 h-4 mr-1 ${flaggedQuestions.includes(currentQuestion?.id) ? "text-yellow-400" : ""}`}
              />
              {flaggedQuestions.includes(currentQuestion?.id)
                ? "Flagged"
                : "Flag for Review"}
            </Button>
          </div>

          {/* Main Content */}
          <div className="flex-1 flex overflow-hidden" ref={contentRef}>
            {/* Passage Section */}
            <div className="w-1/2 p-4 overflow-y-auto">
              <div className="p-4">
                {/* Passage Navigation */}
                <div className="flex justify-between items-center mb-4">
                  <span
                    onClick={handlePreviousPassage}
                    className={`cursor-pointer ${currentPassageIndex === 0 ? "text-gray-400" : "text-blue-600 hover:text-blue-800"}`}
                  >
                    {currentPassageIndex > 0 && "← Previous Passage"}
                  </span>
                  <span
                    onClick={handleNextPassage}
                    className={`cursor-pointer ${currentPassageIndex === passages.length - 1 ? "text-gray-400" : "text-blue-600 hover:text-blue-800"}`}
                  >
                    {currentPassageIndex < passages.length - 1 &&
                      "Next Passage →"}
                  </span>
                </div>

                {/* Passage Title */}
                <h2 className="text-xl font-bold mb-4">
                  {passages.length > 0 && currentPassage && (
                    <>
                      Passage {currentPassageIndex + 1} (Questions{" "}
                      {
                        getQuestionRangeForPassage(currentPassageIndex)
                          .startingQuestion
                      }{" "}
                      -{" "}
                      {
                        getQuestionRangeForPassage(currentPassageIndex)
                          .endingQuestion
                      }
                    </>
                  )}
                </h2>

                {/* Passage Content */}
                <div className="whitespace-pre-wrap font-['Helvetica','Arial',sans-serif] text-[17px] leading-relaxed">
                  {currentPassage.content
                    .split("\n")
                    .map((paragraph, index, array) => (
                      <React.Fragment key={index}>
                        <div className="space-y-0.5">
                          {renderWithMarkup(
                            paragraph
                              .replace(/\.\"  +/g, '." ') // Replace double+ spaces after period+quote
                              .replace(/\!\"  +/g, '!" ') // Replace double+ spaces after exclamation+quote
                              .replace(/\?\"  +/g, '?" ') // Replace double+ spaces after question+quote
                              .replace(/\.  +/g, ". ") // Replace double+ spaces after periods
                              .replace(/\!  +/g, "! ") // Replace double+ spaces after exclamation marks
                              .replace(/\?  +/g, "? "), // Replace double+ spaces after question marks
                            `passage-${currentPassage.id}`,
                            "passage",
                            // Calculate base offset for each paragraph
                            array
                              .slice(0, index)
                              .reduce((sum, p) => sum + p.length + 1, 0),
                          )}
                        </div>
                        {index < array.length - 1 && (
                          <div className="h-[0.75rem]" />
                        )}
                      </React.Fragment>
                    ))}
                  {/* {imageData.current.get("passage").has(currentPassageIndex) &&
                    renderImage("passage")} */}
                </div>

                {/* Passage Title (Optional) */}
                <p className="text-sm text-gray-500 mt-4">
                  {currentPassage.title}
                </p>

                {/* Confidence Rating Section */}
                <div className="mt-4 p-4 bg-gray-200 rounded">
                  <h3 className="text-lg font-semibold mb-2">
                    Confidence Rating
                  </h3>
                  <div className="flex space-x-2">
                    {[
                      {
                        label: "Low",
                        bgColor: "bg-red-600",
                        lightBgColor: "bg-red-100",
                        textColor: "text-red-600",
                        hoverBg: "hover:bg-red-700",
                        hoverText: "hover:bg-red-200",
                      },
                      {
                        label: "Medium",
                        bgColor: "bg-yellow-600",
                        lightBgColor: "bg-yellow-100",
                        textColor: "text-yellow-600",
                        hoverBg: "hover:bg-yellow-700",
                        hoverText: "hover:bg-yellow-200",
                      },
                      {
                        label: "High",
                        bgColor: "bg-green-600",
                        lightBgColor: "bg-green-100",
                        textColor: "text-green-600",
                        hoverBg: "hover:bg-green-700",
                        hoverText: "hover:bg-green-200",
                      },
                    ].map(
                      ({
                        label,
                        bgColor,
                        lightBgColor,
                        textColor,
                        hoverBg,
                        hoverText,
                      }) => (
                        <Button
                          key={label}
                          variant={
                            confidenceRatings[currentQuestion?.id] === label
                              ? "default"
                              : "outline"
                          }
                          onClick={() => handleConfidenceRating(label)}
                          className={`w-full ${
                            confidenceRatings[currentQuestion?.id] === label
                              ? `${bgColor} text-white ${hoverBg}`
                              : `${lightBgColor} ${textColor} ${hoverText}`
                          }`}
                        >
                          {label}
                        </Button>
                      ),
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Question Section */}
            <div className="w-1/2 p-4 overflow-y-auto">
              <div className="p-4">
                {/* Question Title */}
                <h3 className="text-lg font-bold mb-4">
                  Question{" "}
                  {getActualQuestionNumber(
                    currentPassageIndex,
                    currentQuestionIndex,
                  )}
                </h3>

                {/* Question Text */}
                <p className="mb-4 font-['Helvetica','Arial',sans-serif] text-[17px] leading-relaxed">
                  {renderWithMarkup(
                    currentQuestion.text,
                    `question-${currentQuestion.id}`,
                    "question",
                  )}
                  {/* {imageData.current.get("question").has(currentPassageIndex) &&
                    renderImage("question")} */}
                </p>

                {/* Answer Choices */}
                {currentQuestion && currentQuestion.choices && (
                  <RadioGroup
                    key={`radio-group-${currentQuestion.id}`}
                    value={
                      selectedAnswers[currentQuestion.id] !== undefined
                        ? selectedAnswers[currentQuestion.id]?.toString()
                        : undefined
                    }
                    onValueChange={(value) =>
                      handleAnswerSelect(currentQuestion.id, parseInt(value))
                    }
                    className="space-y-3"
                  >
                    {selectedAnswers[currentQuestion.id] === undefined &&
                      isReviewMode && (
                        <div className="mb-4 p-3 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-700">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <AlertCircle
                                className="h-5 w-5 text-yellow-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-3">
                              <p className="text-sm">
                                This question was not answered during the test.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    {currentQuestion.choices
                      .filter((choice) => choice && choice.text)
                      .map((choice, index) => {
                        const { label, text, percentage } = parseChoiceText(
                          choice.text || "",
                        );

                        // Determine if this choice is the correct answer
                        const isCorrect =
                          indexToLabel(index) ===
                          currentQuestion.correct_answer;

                        // Determine if this choice is the user's selected answer
                        const isSelected =
                          selectedAnswers[currentQuestion.id] === index;

                        // Determine if we should show solution (either via showSolution or in review mode)
                        const shouldShowSolution = showSolution || isReviewMode;

                        // Determine styling based on correctness and selection
                        let choiceStyle =
                          "border border-gray-300 hover:bg-gray-50";

                        if (shouldShowSolution) {
                          if (isCorrect) {
                            choiceStyle =
                              "border-2 border-green-500 bg-green-100";
                          }
                          if (isSelected && !isCorrect) {
                            choiceStyle = "border-2 border-red-500 bg-red-100";
                          }
                        }

                        return (
                          <div
                            key={index}
                            className={`flex items-start space-x-3 p-2.5 rounded cursor-pointer ${choiceStyle}`}
                          >
                            <RadioGroupItem
                              value={index.toString()}
                              id={`q${currentQuestion.id}-choice${index}`}
                              checked={isSelected}
                              disabled={
                                isReviewMode ||
                                lockedQuestions[currentQuestion.id]
                              }
                              className="h-5 w-5 mt-0.5 border-2"
                            />
                            <Label
                              htmlFor={`q${currentQuestion.id}-choice${index}`}
                              className="flex-1 font-['Helvetica','Arial',sans-serif] text-[16px] text-gray-900 cursor-pointer leading-relaxed"
                            >
                              {/* Icons to indicate correctness - moved to beginning */}
                              {shouldShowSolution && (
                                <span className="inline-block mr-2">
                                  {isCorrect && (
                                    <CheckCircle
                                      className="inline-block w-4 h-4 text-green-500"
                                      aria-label="Correct Answer"
                                    />
                                  )}
                                  {isSelected && !isCorrect && (
                                    <XCircle
                                      className="inline-block w-4 h-4 text-red-500"
                                      aria-label="Incorrect Selection"
                                    />
                                  )}
                                </span>
                              )}
                              {/* Render the label in bold and the text normally */}
                              {label && (
                                <>
                                  <strong>{label}.</strong>{" "}
                                  {renderWithMarkup(
                                    text,
                                    `choice-${currentQuestion.id}-${index}`,
                                    "choice",
                                  )}
                                  {/* {imageData.current
                                    .get("choice")
                                    .has(currentPassageIndex) &&
                                    renderImage("choice", index)} */}
                                </>
                              )}
                              {!label && (
                                <>
                                  {renderWithMarkup(
                                    text,
                                    `choice-${currentQuestion.id}-${index}`,
                                    "choice",
                                  )}
                                  {/* {imageData.current
                                    .get("choice")
                                    .has(currentPassageIndex) &&
                                    renderImage("choice", index)} */}
                                </>
                              )}

                              {(isReviewMode || showSolution) && percentage && (
                                <span className="ml-2 text-gray-600">
                                  ({percentage}%)
                                </span>
                              )}
                            </Label>
                          </div>
                        );
                      })}
                  </RadioGroup>
                )}

                {/* Action Buttons */}
                <div className="mt-4 space-x-2">
                  {!isReviewMode && (
                    <Button
                      onClick={handleShowSolution}
                      disabled={
                        !showSolution &&
                        selectedAnswers[currentQuestion?.id] === undefined
                      }
                      className="text-white bg-[#0066cc] hover:bg-[#0052a3] disabled:bg-gray-400 disabled:cursor-not-allowed"
                    >
                      {showSolution ? "Hide Solution" : "Show Solution"}
                    </Button>
                  )}
                </div>

                {/* Unified Explanation Block */}
                {(showSolution || isReviewMode) && (
                  <div className="mt-6 p-4 bg-blue-50 border border-blue-200 rounded shadow-md">
                    <h4 className="text-lg font-semibold mb-2 text-blue-800">
                      Explanation:
                    </h4>
                    <div className="text-gray-700 leading-relaxed">
                      <p className="mb-4 text-blue-700 font-medium">
                        The correct answer is: {currentQuestion.correct_answer}
                      </p>
                      {currentQuestion.explanations
                        ? currentQuestion.explanations
                            .split("\n")
                            .map((paragraph, index, array) => (
                              <React.Fragment key={index}>
                                <div className="space-y-0.5">
                                  {renderWithMarkup(
                                    paragraph,
                                    `question-explanation-${currentQuestion.id}`,
                                    "explanation",
                                    array
                                      .slice(0, index)
                                      .reduce(
                                        (sum, p) => sum + p.length + 1,
                                        0,
                                      ),
                                  )}
                                </div>
                                {index < array.length - 1 && (
                                  <div className="h-5" />
                                )}
                              </React.Fragment>
                            ))
                        : "No explanation available for this question."}
                      {/* {currentQuestion.explanations &&
                        renderImage("explanation")} */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {/* Footer with Navigation Buttons */}
      <footer className="bg-gray-200 p-2 flex justify-end items-center">
        {isReviewMode ? (
          <div className="w-full flex justify-between items-center">
            <Button
              variant="default"
              onClick={async () => {
                await finalizeTest();
                navigate("/dashboard");
              }}
              className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
            >
              Back to Dashboard
            </Button>
            <div className="flex space-x-2">
              <Button
                variant="default"
                onClick={handlePreviousQuestion}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              >
                <ChevronLeft className="w-4 h-4 mr-1" /> Previous
              </Button>
              <Button
                variant="default"
                onClick={() => setShowNavigation(true)}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              >
                <Menu className="w-4 h-4 mr-1" /> Navigation
              </Button>
              <Button
                variant="default"
                onClick={handleNextQuestion}
                disabled={isLastQuestion()}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3] disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                Next <ChevronRight className="w-4 h-4 ml-1" />
              </Button>
            </div>
          </div>
        ) : (
          <div className="w-full flex justify-between items-center">
            <Button
              variant="default"
              onClick={handleEndTest}
              className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
            >
              End Session
            </Button>
            <div className="flex space-x-2">
              <Button
                variant="default"
                onClick={handlePreviousQuestion}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              >
                <ChevronLeft className="w-4 h-4 mr-1" /> Previous
              </Button>
              <Button
                variant="default"
                onClick={() => setShowNavigation(true)}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              >
                <Menu className="w-4 h-4 mr-1" /> Navigation
              </Button>
              {isLastQuestion() ? (
                <Button
                  variant="default"
                  onClick={handleEndTest}
                  className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
                >
                  End Test
                </Button>
              ) : (
                <Button
                  variant="default"
                  onClick={handleNextQuestion}
                  className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
                >
                  Next <ChevronRight className="w-4 h-4 ml-1" />
                </Button>
              )}
            </div>
          </div>
        )}
      </footer>

      {/* Navigation Dialog */}
      <Dialog
        open={showNavigation}
        onOpenChange={setShowNavigation}
        id="navigation-dialog"
      >
        <DialogContent
          className="sm:max-w-[800px]"
          style={{
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow:
              "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            maxWidth: "800px",
            maxHeight: "85vh",
            padding: "25px",
          }}
        >
          <DialogHeader>
            <DialogTitle className="flex items-center justify-between">
              <div className="flex items-center">
                <Menu className="w-5 h-5 mr-2" />
                Navigation - Select a question to go to it
              </div>
            </DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[400px] mt-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Question</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Flagged For Review</TableHead>
                  <TableHead>Confidence</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {passages.flatMap((passage, passageIndex) =>
                  passage.questions.map((question, questionIndex) => {
                    const actualQuestionNumber = getActualQuestionNumber(
                      passageIndex,
                      questionIndex,
                    );
                    return (
                      <TableRow
                        key={question.id}
                        className={
                          currentPassageIndex === passageIndex &&
                          currentQuestionIndex === questionIndex
                            ? "bg-yellow-100"
                            : ""
                        }
                      >
                        <TableCell>
                          <Button
                            variant="link"
                            onClick={() => {
                              setCurrentPassageIndex(passageIndex);
                              setCurrentQuestionIndex(questionIndex);
                              setShowNavigation(false);
                            }}
                          >
                            Question {actualQuestionNumber}
                          </Button>
                        </TableCell>
                        <TableCell
                          className={
                            selectedAnswers[question.id] !== undefined
                              ? "text-green-500"
                              : "text-red-500"
                          }
                        >
                          {selectedAnswers[question.id] !== undefined
                            ? "Complete"
                            : "Incomplete"}
                        </TableCell>
                        <TableCell>
                          {flaggedQuestions.includes(question.id) && (
                            <Flag className="h-4 w-4 text-yellow-500" />
                          )}
                        </TableCell>
                        <TableCell>
                          {confidenceRatings[question.id] || "Not set"}
                        </TableCell>
                      </TableRow>
                    );
                  }),
                )}
              </TableBody>
            </Table>
          </ScrollArea>
          <DialogFooter className="flex justify-between items-center">
            <div>{getTotalIncompleteQuestions()} Unseen/Incomplete</div>
            <Button
              onClick={() => setShowNavigation(false)}
              className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
            >
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Feedback Dialog */}
      <Dialog open={showFeedbackDialog} onOpenChange={setShowFeedbackDialog}>
        <DialogContent
          style={{
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow:
              "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            maxWidth: "450px",
            maxHeight: "85vh",
            padding: "25px",
          }}
        >
          <DialogHeader>
            <DialogTitle>Provide Feedback</DialogTitle>
          </DialogHeader>
          <Textarea
            placeholder="Enter your feedback here..."
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
          />
          <DialogFooter>
            <Button
              onClick={handleSubmitFeedback}
              className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              disabled={feedbackText.trim() === ""}
            >
              Submit Feedback
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* End Test Confirmation Dialog */}
      <Dialog
        open={showEndTestConfirmation}
        onOpenChange={setShowEndTestConfirmation}
      >
        <DialogContent
          style={{
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow:
              "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            maxWidth: "450px",
            maxHeight: "85vh",
            padding: "25px",
          }}
        >
          <DialogHeader>
            <DialogTitle>End Test Confirmation</DialogTitle>
          </DialogHeader>
          <p>Are you sure you want to end this test?</p>
          <DialogFooter>
            <Button variant="outline" onClick={cancelEndTest}>
              Cancel
            </Button>
            <Button
              onClick={confirmEndTest}
              className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
            >
              End Test
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Test Summary Dialog */}
      <Dialog open={showTestSummary} onOpenChange={setShowTestSummary}>
        <DialogContent
          className="sm:max-w-[800px]"
          style={{
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow:
              "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            maxWidth: "800px",
            maxHeight: "85vh",
            padding: "25px",
          }}
        >
          <DialogHeader>
            <DialogTitle>Test Summary</DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[400px] mt-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Question</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Flagged For Review</TableHead>
                  <TableHead>Confidence</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {passages.flatMap((passage, passageIndex) =>
                  passage.questions.map((question, questionIndex) => (
                    <TableRow
                      key={question.id}
                      className={
                        currentPassageIndex === passageIndex &&
                        currentQuestionIndex === questionIndex
                          ? "bg-yellow-100"
                          : ""
                      }
                    >
                      <TableCell>
                        Question{" "}
                        {getActualQuestionNumber(passageIndex, questionIndex)}
                      </TableCell>
                      <TableCell
                        className={
                          selectedAnswers[question.id] !== undefined
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      >
                        {selectedAnswers[question.id] !== undefined
                          ? "Complete"
                          : "Incomplete"}
                      </TableCell>
                      <TableCell>
                        {flaggedQuestions.includes(question.id) && (
                          <Flag className="h-4 w-4 text-yellow-500" />
                        )}
                      </TableCell>
                      <TableCell>
                        {confidenceRatings[question.id] || "Not set"}
                      </TableCell>
                    </TableRow>
                  )),
                )}
              </TableBody>
            </Table>
          </ScrollArea>
          <DialogFooter className="flex justify-between items-center">
            <div>{getTotalIncompleteQuestions()} Unseen/Incomplete</div>
            <Button
              onClick={finalizeTest}
              className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
            >
              End
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
