import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/ui/button";
import logoImage from "../assets/images/5.svg";

export default function PrivacyPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-green-50">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 px-4 lg:px-6 h-20 flex items-center bg-white shadow-md bg-opacity-90 backdrop-blur-md">
        <Link className="flex items-center justify-center" to="/">
          <img src={logoImage} alt="UPangea Logo" className="h-14 w-14" />
          <span className="ml-1 text-4xl font-bold bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
            UPangea
          </span>
        </Link>
        <div className="ml-auto">
          <Button
            asChild
            variant="outline"
            className="text-blue-600 border-blue-600 hover:bg-blue-50"
          >
            <Link to="/">Back to Home</Link>
          </Button>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 pt-32 pb-16 max-w-4xl">
        <h1 className="text-4xl font-bold mb-8 text-gray-900">
          Privacy Policy
        </h1>
        <div className="space-y-8 text-gray-700">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              Introduction
            </h2>
            <p className="mb-4">
              At UPangea, we take your privacy seriously. This Privacy Policy
              explains how we collect, use, disclose, and safeguard your
              information when you use our website and services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              Information We Collect
            </h2>
            <h3 className="text-xl font-medium mb-2">Personal Information</h3>
            <ul className="list-disc pl-6 mb-4">
              <li>Name and email address when you create an account</li>
              <li>Payment information when you subscribe to our services</li>
              <li>Profile information you provide</li>
              <li>Study progress and performance data</li>
            </ul>

            <h3 className="text-xl font-medium mb-2">Usage Information</h3>
            <ul className="list-disc pl-6">
              <li>Log data and device information</li>
              <li>Study patterns and preferences</li>
              <li>Time spent on different sections</li>
              <li>Performance metrics and progress data</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              How We Use Your Information
            </h2>
            <ul className="list-disc pl-6">
              <li>To provide and maintain our services</li>
              <li>To personalize your learning experience</li>
              <li>To analyze and improve our platform</li>
              <li>To communicate with you about updates and changes</li>
              <li>To process your payments and subscriptions</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              Data Security
            </h2>
            <p className="mb-4">
              We implement appropriate technical and organizational measures to
              maintain the security of your personal information. However,
              please note that no method of transmission over the internet or
              electronic storage is 100% secure.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              Your Rights
            </h2>
            <p className="mb-4">You have the right to:</p>
            <ul className="list-disc pl-6">
              <li>Access your personal information</li>
              <li>Correct inaccurate data</li>
              <li>Request deletion of your data</li>
              <li>Object to data processing</li>
              <li>Request data portability</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              Contact Us
            </h2>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at:{" "}
              <a
                href="mailto:privacy@upangea.com"
                className="text-blue-600 hover:underline"
              >
                privacy@upangea.com
              </a>
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              Updates to This Policy
            </h2>
            <p>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on
              this page and updating the "Last Updated" date below.
            </p>
            <p className="mt-4 font-medium">Last Updated: January 1, 2024</p>
          </section>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-300 py-6">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <p className="text-sm">© 2024 UPangea Inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}
