import React from "react";

export function RadioGroup({ children, value, onValueChange }) {
  return (
    <div>
      {React.Children.map(children, (child) => {
        if (!child) return null;
        return React.cloneElement(child, {
          name: "radio-group",
          checked: child.props?.value === value,
          onChange: (e) => onValueChange(e.target.value),
        });
      })}
    </div>
  );
}

export function RadioGroupItem({ id, value, checked, disabled, className, ...props }) {
  return (
    <input
      type="radio"
      id={id}
      value={value || ""}
      checked={checked || false}
      disabled={disabled || false}
      className={className}
      name="radio-group"
      {...props}
    />
  );
}
