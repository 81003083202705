import React, { useState } from "react";

export function Collapsible({ children, ...props }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div {...props}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { isOpen, setIsOpen }),
      )}
    </div>
  );
}

export function CollapsibleTrigger({ children, isOpen, setIsOpen, ...props }) {
  const handleClick = (e) => {
    if (e.target.type !== "checkbox") {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(e.target.checked);
    }
  };

  return (
    <div onClick={handleClick} {...props}>
      {children}
    </div>
  );
}

export function CollapsibleContent({ children, isOpen, ...props }) {
  if (!isOpen) return null;
  return <div {...props}>{children}</div>;
}
