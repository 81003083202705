import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import TestInterface from "./pages/TestInterface";
import Navigation from "./components/navigation";
import PrivacyPage from "./pages/PrivacyPage";
import TermsOfService from "./pages/TermsofService";
import Contact from "./pages/Contact";
import Beta from "./pages/Beta";
import { Analytics } from "@vercel/analytics/react";
import { SessionContext } from "./index";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const getRedirectUrl = () => {
  return 'http://localhost:3000/dashboard';
};

const PrivateRoute = ({ children }) => {
  const session = React.useContext(SessionContext);
  const [loading, setLoading] = React.useState(true);
  const [hasCheckedSession, setHasCheckedSession] = React.useState(false);

  React.useEffect(() => {
    const checkSession = async () => {
      try {
        const { data: { session: currentSession } } = await supabase.auth.getSession();
        if (currentSession) {
          setHasCheckedSession(true);
          setLoading(false);
        } else {
          setTimeout(() => {
            setHasCheckedSession(true);
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        console.error('Session check error:', error);
        setHasCheckedSession(true);
        setLoading(false);
      }
    };

    checkSession();
  }, []);

  if (loading || !hasCheckedSession) {
    return null;
  }

  return session ? children : <Navigate to="/" replace />;
};

function App() {
  const [initialized, setInitialized] = React.useState(false);
  const session = React.useContext(SessionContext);

  React.useEffect(() => {
    const handleAuth = async () => {
      try {
        const hash = window.location.hash;
        const query = new URLSearchParams(window.location.search);
        const accessToken = query.get('access_token') || (hash && new URLSearchParams(hash.substring(1)).get('access_token'));

        if (accessToken) {
          console.log('OAuth return detected, waiting for session...');
          await new Promise(resolve => setTimeout(resolve, 1000));
          const { data: { session: currentSession } } = await supabase.auth.getSession();
          if (currentSession) {
            console.log('Session established, redirecting to dashboard');
            const isProd = window.location.hostname === 'www.upangea.com' || window.location.hostname === 'upangea.com';
            const redirectUrl = isProd ? 'https://www.upangea.com/dashboard' : 'http://localhost:3000/dashboard';
            window.location.replace(redirectUrl);
            return;
          }
        }
        setInitialized(true);
      } catch (error) {
        console.error('Auth handling error:', error);
        setInitialized(true);
      }
    };

    handleAuth();
  }, [session]);

  if (!initialized) {
    return null;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route 
            path="/" 
            element={session ? <Navigate to="/dashboard" replace /> : <LandingPage />} 
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/test/:testId"
            element={
              <PrivateRoute>
                <TestInterface />
              </PrivateRoute>
            }
          />
          <Route
            path="/test/:testId/review"
            element={
              <PrivateRoute>
                <TestInterface isReviewMode={true} />
              </PrivateRoute>
            }
          />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/beta" element={<Beta />} />
        </Routes>
      </Router>
      <Analytics />
    </>
  );
}

export default App;
